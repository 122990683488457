import React from 'react';
export default class Badge extends React.Component {
  render() {



    if(this.props.link){
      return (
        <span onClick={()=>{window.location = this.props.link}} style={{cursor:"pointer",whiteSpace:"nowrap",margin:this.props.size,marginLeft:this.props.size,fontSize:this.props.size*2.1,padding:this.props.size,paddingTop:this.props.size*1.9,backgroundColor:this.props.color,borderRadius:this.props.size}}>
          {this.props.outer}
          <span style={{whiteSpace:"nowrap",padding:this.props.size/2,marginLeft:this.props.size,fontSize:this.props.size*3.333,backgroundColor:this.props.hightlight,borderRadius:this.props.size/2}}>
            {this.props.inner}
          </span>
        </span>
      )
    }else{
      return (
        <span style={{whiteSpace:"nowrap",margin:this.props.size,marginLeft:this.props.size,fontSize:this.props.size*2.1,padding:this.props.size,paddingTop:this.props.size*1.9,backgroundColor:this.props.color,borderRadius:this.props.size}}>
          {this.props.outer}
          <span style={{whiteSpace:"nowrap",padding:this.props.size/2,marginLeft:this.props.size,fontSize:this.props.size*3.333,backgroundColor:this.props.hightlight,borderRadius:this.props.size/2}}>
            {this.props.inner}
          </span>
        </span>
      )
    }

  }
}
