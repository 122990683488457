import React from 'react';
import { Metamask, Gas, ContractLoader, Transactions, Events, Scaler, Blockie, Address, Button } from "dapparatus"
import Helpers from "../helpers.js"

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    let backgroundColorString = this.props.backgroundColor.replace("0x","")
    let backgroundColorRed = parseInt(backgroundColorString[0]+backgroundColorString[1],16)
    let backgroundColorGreen = parseInt(backgroundColorString[2]+backgroundColorString[3],16)
    let backgroundColorBlue = parseInt(backgroundColorString[4]+backgroundColorString[5],16)
    let colorVariation = 10
    let land = []
    for(let x=0;x<this.props.size;x++){
      if(!land[x]) land[x] = []
      for(let y=0;y<this.props.size;y++){
        let variationColor = (backgroundColorRed+Math.round(Math.random()*colorVariation)-Math.round(Math.random()*colorVariation)).toString(16)+
          (backgroundColorGreen+Math.round(Math.random()*colorVariation)-Math.round(Math.random()*colorVariation)).toString(16)+
          (backgroundColorBlue+Math.round(Math.random()*colorVariation)-Math.round(Math.random()*colorVariation)).toString(16)
        if(!land[x][y]) land[x][y] = {color:variationColor}
      }
    }
    this.state = {
      land: land,
      centerX: this.props.size/2,
      centerY: this.props.size/2,
    }
  }
  componentDidMount(){
    setTimeout(()=>{
      console.log("Scrolling based on height ",window.innerHeight)
      let scrollToHeight = Math.round(window.innerHeight/3)
      console.log("scrollToHeight",scrollToHeight)
      window.scrollTo(0,scrollToHeight)
    },1000)
    setInterval(this.landPoll.bind(this),2555)
    setTimeout(this.landPoll.bind(this),555)
    setTimeout(this.landPoll.bind(this),1555)
  }
  async landPoll() {
    let emojiToContract = {}
    //console.log("Looking through my land...")
    let landUpdate = this.state.land
    let update = false
    for(let x=0;x<this.props.size;x++){
      for(let y=0;y<this.props.size;y++){
        if(this.state.land[x][y] && this.state.land[x][y].objectType && this.state.land[x][y].owner == this.props.account && this.state.land[x][y].emoji){
          //console.log("TILE NEEDS TO LOAD CONTRACT FOR ",x,y,this.state.land[x][y])
          let contract = this.getContractForEmoji(this.state.land[x][y].emoji)
          if(contract){
            //console.log("CONTRACT",contract)
            if(typeof contract.onCollect == "function"){
              landUpdate[x][y].lastBlock = parseInt(await contract.lastBlock(this.props.contracts["DAOG"]._address,x,y).call())
              let currentBlock = await this.props.web3.eth.getBlockNumber()
              let blocksPassed = currentBlock - landUpdate[x][y].lastBlock
              let earningsLength = await contract.earningsLength().call()
              let earnings = []
              //console.log("Found ",earningsLength," earnings...")
              for(let e=0;e<earningsLength;e++){
                let thisEarning = await contract.earnings(e).call()
                //console.log("thisEarning",thisEarning)
                if(landUpdate[x][y].lastBlock==0){
                  thisEarning.toGo = -1
                }else if(thisEarning.blocks<=blocksPassed){
                  thisEarning.toGo = 0
                }else{
                  thisEarning.toGo = thisEarning.blocks - blocksPassed
                }
                earnings.push({
                  emoji: this.props.web3.utils.hexToUtf8(thisEarning.emoji),
                  emojiBytes: thisEarning.emoji,
                  amount: thisEarning.amount,
                  blocks: thisEarning.blocks,
                  toGo: thisEarning.toGo
                })
              }
              if(earningsLength>0){
                landUpdate[x][y].earnings = earnings
                update=true
              }
            }
          }
        }
      }
    }
    if(update){
      this.setState({land:landUpdate})
    }
  }
  render() {
    return (
      <div>
        MAP! will need to copy a lot of the rendering in game to functions that can be called here too
      </div>
    )
  }
}
