import React from 'react';
import { Button, Blockie } from "dapparatus"
import Helpers from "../helpers.js"
import Loader from "./Loader.js"

const toEmoji = require("emoji-name-map");


export default class MenuObjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      earnEmojis: [],
      earnAmounts: [],
      earnBlocks: [],
      costEmojis: [],
      costAmounts: [],
      objectType: 1,
      builtOn: "",
      builtNear: "",
      plantedOn:"",
      growToEmoji:"",
      influence: 0,
      myMechanicsLoaded:[],
      gameMechanicsLoaded:[],
      daogMechanicsLoaded:[],
    }
  }
  componentDidMount(){
    this.init()
  }
  async init(){
    let loaded = []
    let myMechanicsLoaded = []
    console.log("myMechanics",this.props.myMechanics)
    let total = 0
    //lol at me getting the total here
    for(let m in this.props.myMechanics){
      total++
    }
    let count = 0
    for(let m in this.props.myMechanics){
        count++
      if(loaded.indexOf(this.props.myMechanics[m])>=0){
        //exists already
      }else if(this.props.myMechanics[m]!="0x0000000000000000000000000000000000000000"){
         //try only loading the most recent 30
        //if( total < 100 || total-count < 100){
        if(count > this.props.myMechanics.length - 100){
            loaded.push(this.props.myMechanics[m])
            let contract = await this.props.loadContractForGenericObject(this.props.myMechanics[m])
            console.log("contract",count,total,contract)
            myMechanicsLoaded.push(contract)
            if(count%5==0){
              this.setState({myMechanicsLoaded})
            }
        }
      }
    }
    /*let gameMechanicsLoaded = []
    console.log("gameMechanics",this.props.gameMechanics)
    for(let m in this.props.gameMechanics){
      if(loaded.indexOf(this.props.gameMechanics[m].mechanic)>=0){
        //exists already
      }else if(this.props.gameMechanics[m].mechanic!="0x0000000000000000000000000000000000000000"){
        loaded.push(this.props.gameMechanics[m].mechanic)
        let contract = await this.props.loadContractForGenericObject(this.props.gameMechanics[m].mechanic)
        console.log("contract",contract)
        gameMechanicsLoaded.push(contract)
      }
    }
    let daogMechanicsLoaded = []
    console.log("daogMechanics",this.props.daogMechanics)
    for(let m in this.props.daogMechanics){
      if(loaded.indexOf(this.props.daogMechanics[m].mechanic)>=0){
        //exists already
      }else if(this.props.daogMechanics[m].mechanic!="0x0000000000000000000000000000000000000000"){
        loaded.push(this.props.daogMechanics[m].mechanic)
        let contract = await this.props.loadContractForGenericObject(this.props.daogMechanics[m].mechanic)
        console.log("contract",contract)
        daogMechanicsLoaded.push(contract)
      }
    }*/


  }
  handleInput(e){
    let update = {}
    let emojiValue = false
    try{
      emojiValue = toEmoji.get(e.target.value)
    }catch(e){console.log(e)}
    update[e.target.name] = e.target.value
    if(emojiValue){
      update["emojiValue"] = emojiValue
    }
    this.setState(update)
  }
  change(id,event){
    console.log("CHANGE",id,event)
    let update = {}
    update[id]=event.target.value
    this.setState(update,()=>{
      if(id=="buyTokenIn"||id=="buyTokenOut"){
        this.calculateMax()
      }else if(id=="sellTokenIn"||id=="sellTokenOut"){
        this.calculateMin()
      }
      if(id=="objectType"){
        console.log("OBJTYPE: event.target.value",update[id])
      }
    });
  }
  possibleEmojiButton(name){
    if(!this.state.emojiValue){
      return (<span></span>)
    }else{
      return (
        <Button size="1" color={"blue"} onClick={()=>{
          let update = {emojiValue:""}
          update[name] = this.state.emojiValue
          this.setState(update)
        }}>
          {this.state.emojiValue}
        </Button>
      )
      }
  }
  render() {
    let {} = this.state
    let objectTypeOptions = []

    for( let o in Helpers.objectTypeToIndex ){
      //console.log("OBJETTYPE",o,Helpers.objectTypeToIndex[o])
      objectTypeOptions.push(
        <option value={Helpers.objectTypeToIndex[o]}>
          {o}
        </option>
      )
    }

    let contractType = Helpers.objectType[this.state.objectType]

    let deployEmoji = "0x0000000000000000"
    if(this.state.deployEmoji) deployEmoji = this.state.deployEmoji
    let deployArgs = [Helpers.emoji(this.props.web3,deployEmoji)]

    let inputForm = []


    console.log("this.state.objectType",this.state.objectType,"Helpers.objectTypeToIndex['Discoverable']",Helpers.objectTypeToIndex['Discoverable'])
    if(contractType == 'Discoverable'){

      deployArgs.push(this.state.probability)

      inputForm.push(
        <div style={{padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
          probability(0-65535):<input
            style={{verticalAlign:"middle",fontSize:38,width:150,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="probability" value={this.state.probability} onChange={this.handleInput.bind(this)}
          />({Math.round((this.state.probability?this.state.probability:0)*100/65535)}%)
        </div>
      )

    }


    if(contractType == 'Growable'){
      deployArgs.push(Helpers.emoji(this.props.web3,this.state.plantedOn))
      deployArgs.push(this.state.blocksToGrow)
      deployArgs.push(Helpers.emoji(this.props.web3,this.state.growToEmoji))

      inputForm.push(
        <div style={{padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
          planted on:
          {this.possibleEmojiButton("plantedOn")}<input
            style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="plantedOn" value={this.state.plantedOn} onChange={this.handleInput.bind(this)}
          />
          blocks:
          <input
            style={{verticalAlign:"middle",fontSize:38,width:80,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="blocksToGrow" value={this.state.blocksToGrow} onChange={this.handleInput.bind(this)}
          />
          grow emoji:
          {this.possibleEmojiButton("growToEmoji")}<input
            style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="growToEmoji" value={this.state.growToEmoji} onChange={this.handleInput.bind(this)}
          />
        </div>
      )

    }



    if(contractType == 'Buildable'){
      deployArgs.push(Helpers.emoji(this.props.web3,this.state.builtOn))
      deployArgs.push(this.state.influence)

      inputForm.push(
        <div style={{padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
          built on:
          {this.possibleEmojiButton("builtOn")}<input
            style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="builtOn" value={this.state.builtOn} onChange={this.handleInput.bind(this)}
          />
          influence:
          <input
            style={{verticalAlign:"middle",fontSize:38,width:80,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="influence" value={this.state.influence} onChange={this.handleInput.bind(this)}
          />
        </div>
      )
    }

    if(contractType == 'ProximityBuildable'){
      deployArgs.push(Helpers.emoji(this.props.web3,this.state.builtOn))
      deployArgs.push(Helpers.emoji(this.props.web3,this.state.builtNear))
      deployArgs.push(this.state.influence)

      inputForm.push(
        <div style={{padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
          built on:
          {this.possibleEmojiButton("builtOn")}<input
            style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="builtOn" value={this.state.builtOn} onChange={this.handleInput.bind(this)}
          />
          near:
          {this.possibleEmojiButton("builtNear")}<input
            style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="builtNear" value={this.state.builtNear} onChange={this.handleInput.bind(this)}
          />
          influence:
          <input
            style={{verticalAlign:"middle",fontSize:38,width:80,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="influence" value={this.state.influence} onChange={this.handleInput.bind(this)}
          />
        </div>
      )
    }



    if(contractType == 'Buildable' || contractType == 'Growable' || contractType == 'ProximityBuildable'){
      let list = []
      for(let e in this.state.costEmojis){
        list.push(
          <div key={"list"+e}>{this.state.costEmojis[e]},{this.state.costAmounts[e]}</div>
        )
      }
      let emojiArray = []
      for (let ce in this.state.costEmojis){
        emojiArray[ce] = Helpers.emoji(this.props.web3,this.state.costEmojis[ce])
      }
      deployArgs.push(emojiArray,this.state.costAmounts)
      inputForm.push(
          <div style={{padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
            <div style={{color:"#dddddd"}}>COSTS</div>
            emoji:
            {this.possibleEmojiButton("costEmoji")}<input
              style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
              type="text" name="costEmoji" value={this.state.costEmoji} onChange={this.handleInput.bind(this)}
            />
            cost:
            <input
              style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
              type="text" name="costAmount" value={this.state.costAmount} onChange={this.handleInput.bind(this)}
            />

            <Button size="2" color={"blue"} onClick={()=>{
              if(this.state.costEmoji && this.state.costAmount){
                let costEmojis = this.state.costEmojis
                let costAmounts = this.state.costAmounts
                costEmojis.push(this.state.costEmoji)
                costAmounts.push(this.state.costAmount)
                this.setState({costEmoji:"",costAmount:"",costEmojis,costAmounts})
              }
            }}>
              add
            </Button>
            {list}
          </div>
        )
    }


    if(contractType == 'Discoverable' || contractType == 'Buildable' || contractType == 'ProximityBuildable'){
      let list = []
      for(let e in this.state.earnEmojis){
        list.push(
          <div key={"list"+e}>{this.state.earnEmojis[e]},{this.state.earnAmounts[e]},{this.state.earnBlocks[e]}</div>
        )
      }
      let emojiArray = []
      for (let ee in this.state.earnEmojis){
        emojiArray[ee] = Helpers.emoji(this.props.web3,this.state.earnEmojis[ee])
      }
      deployArgs.push(emojiArray,this.state.earnAmounts,this.state.earnBlocks)
      inputForm.push(
          <div style={{padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
            <div style={{color:"#dddddd"}}>EARNINGS</div>
            emoji:
            {this.possibleEmojiButton("earnEmoji")}<input
              style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
              type="text" name="earnEmoji" value={this.state.earnEmoji} onChange={this.handleInput.bind(this)}
            />
            amount:
            <input
              style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
              type="text" name="earnAmount" value={this.state.earnAmount} onChange={this.handleInput.bind(this)}
            />
            blocks:
            <input
              style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
              type="text" name="earnBlock" value={this.state.earnBlock} onChange={this.handleInput.bind(this)}
            />
            <Button size="2" color={"blue"} onClick={()=>{
              if(this.state.earnEmoji && this.state.earnAmount && this.state.earnBlocks){
                let earnEmojis = this.state.earnEmojis
                let earnAmounts = this.state.earnAmounts
                let earnBlocks = this.state.earnBlocks
                earnEmojis.push(this.state.earnEmoji)
                earnAmounts.push(this.state.earnAmount)
                earnBlocks.push(this.state.earnBlock)
                this.setState({earnEmoji:"",earnAmount:"",earnBlock:"",earnEmojis,earnAmounts,earnBlocks})
              }
            }}>
              add
            </Button>
            {list}
          </div>
        )
    }

    let top = 0
    if(this.state.phase>1){
      top = this.props.topPadding
    }
//{JSON.stringify(this.props.myMechanics)},{JSON.stringify(this.props.gameMechanics)},{JSON.stringify(this.props.daogMechanics)}

    let recentMine = []
    for(let i in this.state.myMechanicsLoaded){
      //console.log(this.state.myMechanicsLoaded[i])
      recentMine.push(
        <span key={"myrecent"+i} style={{padding:10,cursor:"pointer"}} onClick={()=>{
          this.props.setContractMenu(this.state.myMechanicsLoaded[i],"setAddingObjects")
        }}>
          {this.state.myMechanicsLoaded[i].loadedEmoji}
        </span>
      )
    }

    let recentGame = []
    for(let i in this.state.gameMechanicsLoaded){
      //console.log(this.state.gameMechanicsLoaded[i])
      recentGame.push(
        <span key={"gamerecent"+i} style={{padding:10,cursor:"pointer"}} onClick={()=>{
          /*let object ={
            address: this.state.gameMechanicsLoaded[i]._address,
            emoji: this.state.gameMechanicsLoaded[i].loadedEmoji
          }
          this.props.setAddingObjects(object)
          this.props.closeMenu()*/
        }}>
          {this.state.gameMechanicsLoaded[i].loadedEmoji}
        </span>
      )
    }

    let recentDAOG = []
    for(let i in this.state.daogMechanicsLoaded){
      //console.log(this.state.daogMechanicsLoaded[i])
      recentGame.push(
        <span key={"daogrecent"+i} style={{padding:10,cursor:"pointer"}} onClick={()=>{
          /*let object ={
            address: this.state.daogMechanicsLoaded[i]._address,
            emoji: this.state.daogMechanicsLoaded[i].loadedEmoji
          }
          this.props.setAddingObjects(object)
          this.props.closeMenu()*/
        }}>
          {this.state.daogMechanicsLoaded[i].loadedEmoji}
        </span>
      )
    }

    if(!recentMine||recentMine.length<1){
      recentMine=(
        <Loader {...this.props}/>
      )
    }

    return (
      <div key="menu" style={{zIndex:31,overflow:"scroll",padding:"2vw",position:'fixed',opacity:0.95,left:0,top:top,width:"100%",height:"100%",backgroundColor:"#333333"}}>
        <div style={{zIndex:31,cursor:"pointer",fontSize:"6vw",position:'absolute',right:"4%",top:"6%"}} onClick={this.props.closeMenu}>
          ❌
        </div>
        <div style={{fontSize:"3vw",left:"2%",top:"2%",opacity:0.5}} >
          SELECT MECHANIC
        </div>

        <div style={{marginTop:"2%",fontSize:"4vw",opacity:0.8}} >
          RECENT
        </div>

        <div style={{marginTop:"2%",fontSize:"3vw",opacity:0.8,paddingLeft:"3%"}} >
          {recentMine} {recentGame} {recentDAOG}
        </div>

        <div style={{marginTop:"2%",fontSize:"4vw",opacity:0.8}} >
          DEPLOY
        </div>
        <div style={{fontSize:"4vw",opacity:0.95,padding:10,backgroundColor:"#999999"}} >

        {this.possibleEmojiButton("deployEmoji")}
          <input
            style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="deployEmoji" value={this.state.deployEmoji} onChange={this.handleInput.bind(this)}
          /> <select style={{fontSize:38}} id={"objectType"} onChange={this.change.bind(this,"objectType")} value={this.state["objectType"]}>
            {objectTypeOptions}
          </select>
          {inputForm}
          <div>
            <Button size="3" color={this.state.deployEmoji?"green":"gray"} onClick={()=>{

              console.log("contractType",contractType)
              console.log("original contract",this.props.contracts[contractType])
              if(!this.state.deployEmoji){
                return
              }
              console.log("DEPLOYING ",this.state.deployEmoji,"deployArgs",deployArgs)
              this.props.setLoader(true,5,this.state.deployEmoji+" Deploying...")
              this.props.tx(this.props.contracts[contractType]._contract.deploy({
                data:"0x"+require("../contracts/"+contractType+".bytecode.js"),
                arguments:deployArgs
              }),8500000,async (receipt,err)=>{
                this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
                console.log("~~~~~~ "+contractType+" DEPLOY FROM DAPPARATUS:",receipt)
                if(receipt.contractAddress){
                  this.props.addMyMechanics({mechanic:receipt.contractAddress})
                  let inventoryAddress = receipt.contractAddress
                  let inventoryContract = this.props.contractLoader(contractType,inventoryAddress)
                  let inventoryEmoji = await inventoryContract.emoji().call()
                  if(inventoryEmoji){

                    console.log("inventoryEmoji:",inventoryEmoji)

                    let object ={
                      address: inventoryAddress,
                      emoji: this.props.web3.utils.toUtf8(inventoryEmoji)
                    }
                    console.log(contractType+" CONTRACT DEPLOYED:",object)
                    this.props.setAddingObjects(object)
                    this.props.closeMenu()
                  }
                  /// ping the history contract so we can track all contracts deployed for a specific emoji too
                  this.props.tx(this.props.contracts["History"].deployEmoji(inventoryEmoji,receipt.contractAddress),100000,async (receipt,err)=>{
                    console.log("History Receipt",receipt)
                  })
                }
              })
            }}>
              Deploy Mechanic
            </Button>
          </div>
        </div>


        <div style={{marginTop:"2%",fontSize:"4vw",opacity:0.8}} >
          CUSTOM
        </div>

        <div>
          address: <input
            style={{verticalAlign:"middle",fontSize:38,width:250,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="customAddress" value={this.state.customAddress} onChange={this.handleInput.bind(this)}
          /> <div>
            <Button size="3" color={this.state.customAddress?"green":"gray"} onClick={async ()=>{
              if(!this.state.customAddress){
                return
              }
              this.props.addMyMechanics(this.state.customAddress)
              let inventoryAddress = this.state.customAddress
              let inventoryContract = this.props.contractLoader(contractType,inventoryAddress)
              let inventoryEmoji = await inventoryContract.emoji().call()
              if(inventoryEmoji){
                console.log("inventoryEmoji:",inventoryEmoji)
                let object ={
                  address: inventoryAddress,
                  emoji: this.props.web3.utils.toUtf8(inventoryEmoji)
                }
                console.log(contractType+" CONTRACT DEPLOYED:",object)
                this.props.setAddingObjects(object)
                this.props.closeMenu()
              }
            }}>
              Add Mechanic
            </Button>
          </div>
        </div>
        <div style={{textAlign:'center',padding:"5%",paddingTop:"9%"}}>
          <Button size="3" color={"orange"} onClick={async ()=>{
            this.props.closeMenu()
          }}>
            Cancel
          </Button>
        </div>
      </div>
    )
  }
}
