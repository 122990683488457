
let cleanNonEmojiChars = function(emoji){
  let onlyEmojis = emoji.replace(/\b(?!\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])\b\S+/,"")
  onlyEmojis = onlyEmojis.replace(/\s/g, '');
  onlyEmojis = onlyEmojis.replace(/[\n\r\t]/g, "");
  onlyEmojis = onlyEmojis.replace(/[\x00-\x1F\x7F-\x9F]/g, "");
  return onlyEmojis
}

module.exports = {
  /*
  ObjectType {
      Null,
      Buildable,
      Discoverable,
      Inventory
  }
   */
  objectType: {
    1: "Inventory",
    2: "Buildable",
    3: "Discoverable",
    4: "Growable",
    5: "ProximityBuildable"
  },
  objectColor: {
    1: ["#b09c3d","#e9da96"],
    2: ["#252f90","#8c94e6"],
    3: ["#724496","#cca8e7"],
    4: ["#3e833c","#80d67e"],
  },
  objectTypeToIndex: {
    "Inventory": 1,
    "Buildable": 2,
    "Discoverable": 3,
    "Growable": 4,
    "ProximityBuildable": 5
  },
  blockClock: function(blocksToGo){
    let possibleClocks = [
      "🕛","🕧","🕐","🕜","🕑","🕝","🕒","🕞","🕓","🕟","🕔","🕠",
      "🕕","🕡","🕖","🕢","🕗","🕣","🕘","🕤","🕙","🕥","🕚","🕦",
    ]
    return possibleClocks[blocksToGo%24]
  },emoji:(web3,emojiUtf8)=>{
    let emojiHex = (web3.utils.toHex(cleanNonEmojiChars(emojiUtf8))).replace("efb88f","").replace("0x","")
    if(emojiHex.length<10){
      emojiHex = "0x"+emojiHex+"0".repeat(12-emojiHex.length)
    }else {
      emojiHex = "0x"+emojiHex
    }
    return emojiHex;
  },toEmoji:(web3,emojiHex)=>{
    if(emojiHex&&emojiHex!="0x0000000000000000"){
      return web3.utils.toUtf8(emojiHex)
    }
    return "";
  },
  neighbors: function(x,y,size,hexagon){
    let neighbors = []
    for(let xOff=-1;xOff<=1;xOff++){
      for(let yOff=-1;yOff<=1;yOff++){
        if( (hexagon && xOff!=yOff) || (!hexagon && xOff!=0 && yOff!=0)) {
          let thisX = x+xOff
          let thisY = y+yOff
          if(thisX<0) {
            thisX+=size
          }else if(thisX>size-1){
            thisX-=size
          }
          if(thisY<0) {
            thisY+=size
          }else if(thisY>size-1){
            thisY-=size
          }
          neighbors.push([thisX,thisY])
        }
      }
    }
    return neighbors
  },
  cleanNonEmojiChars: cleanNonEmojiChars

}
