import React from 'react';
import { Button, Blockie, Scaler } from "dapparatus"
import Badge from './Badge'
import Loader from './Loader'
import Helpers from "../helpers.js"
import VictoryReport from "./VictoryReport.js"

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tribute: "",
    }
  }
  handleInput(e){
    let update = {}
    update[e.target.name] = e.target.value
    this.setState(update)
  }
  wrapEmoji(emoji,address){
      return (
          <span style={{cursor:"pointer"}} onClick={async (e)=>{
            e.stopPropagation();
            this.props.setContractMenu(await this.props.loadContractForGenericObject(address),"")
          }}>
            {emoji}
          </span>
      )
  }
  render() {
    //console.log("this.props.openProposals",this.props.openProposals)
    let debugInfo = ""
    if(this.props.debug){
      debugInfo = (
        <div>
          currentPeriod:{this.props.currentPeriod} votingPeriodLength:{this.props.votingPeriodLength} gracePeriodLength:{this.props.gracePeriodLength}
        </div>
      )
    }

    if(!this.props.player || this.props.player.playerAddress=="0x0000000000000000000000000000000000000000"){
      return (
        <div key="footer" style={{paddingTop:10,backgroundColor:"#111111",position:'fixed',bottom:0,left:0,width:"100%",height:(150)*this.props.heightRatio/100*1.13}}>
          <div style={{paddingTop:10,marginTop:10}}>
          </div>
          <VictoryReport {...this.props} />
        </div>
      )
    }

    let anySleeping = false

    let proposals = []

    for(let p=0;p<this.props.proposals.length;p++){
      //console.log("draw",this.props.proposals[p])
      if(this.props.proposals && this.props.proposals[p] && !this.props.proposals[p].processed){

        let button = ""

        let startingPeriod = parseInt(this.props.proposals[p].startingPeriod)
        let votingPeriod = startingPeriod+this.props.votingPeriodLength
        let gracePeriod = startingPeriod+this.props.votingPeriodLength+this.props.gracePeriodLength

        let status = startingPeriod-this.props.currentPeriod
        let outer = "⏰"

        if(this.props.currentPeriod>=startingPeriod){

          status=votingPeriod-this.props.currentPeriod
          outer="🗳️"

          if(this.props.proposals[p].myVote==1){
            button = (
              <span>👍</span>
            )
          }else if(this.props.proposals[p].myVote==2){
            button = (
              <span>👎</span>
            )
          }else{
            button = (
              <span>
                <Button color={"green"} size={"2"} onClick={async ()=>{
                    if(!this.props.inventory || !this.props.inventory[this.props.utilityEmoji] || this.props.inventory[this.props.utilityEmoji]<this.props.costToVote){
                      this.props.setLoaderByTransaction({},"Not enough "+this.props.utilityEmoji,5000)
                      return;
                    }

                    //submitVote(uint256 proposalIndex, uint8 uintVote)
                    console.log("SUBMIT VOTE: ",this.props.proposals[p].proposalIndex)
                    this.props.setLoader(true,5,"👍 Voting...")
                    this.props.tx(this.props.contracts["Moloch"].submitVote(""+this.props.proposals[p].proposalIndex,"1"),500000,(receipt,err)=>{
                      this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
                      console.log(receipt)
                    })
                  }}>
                  <Badge outer={this.props.utilityEmoji} inner={this.props.costToVote}
                    size={6} color={Helpers.objectColor[1][0]} hightlight={Helpers.objectColor[1][1]}
                  />👍
                </Button>
                <Button color={"blue"} size={"2"} onClick={async ()=>{
                    if(!this.props.inventory || !this.props.inventory[this.props.utilityEmoji] || this.props.inventory[this.props.utilityEmoji]<this.props.costToVote){
                      this.props.setLoaderByTransaction({},"Not enough "+this.props.utilityEmoji,5000)
                      return;
                    }
                    //submitVote(uint256 proposalIndex, uint8 uintVote)
                    this.props.setLoader(true,5,"👎 Voting...")
                    this.props.tx(this.props.contracts["Moloch"].submitVote(""+this.props.proposals[p].proposalIndex,"2"),500000,(receipt,err)=>{
                      this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
                      console.log(receipt)
                    })
                  }}>
                  <Badge outer={this.props.utilityEmoji} inner={this.props.costToVote}
                    size={6} color={Helpers.objectColor[1][0]} hightlight={Helpers.objectColor[1][1]}
                  />👎
                </Button>
              </span>
            )
          }

        }
        if(this.props.currentPeriod>=votingPeriod){
          anySleeping=true
          outer = "💤"
          status = gracePeriod-this.props.currentPeriod
          button = ""
        }
        if(this.props.currentPeriod>=gracePeriod){
          if(this.props.proposals[p].yesVotes > this.props.proposals[p].noVotes){
            status = ""
            outer = "✅"
          }else{
            status = ""
            outer = "❎"
          }

          button = (
            <Button color={"blue"} size={"2"} onClick={async ()=>{
                this.props.setLoader(true,5,"⚙️ Processing...")
                //processProposal(uint256 proposalIndex)
                this.props.tx(this.props.contracts["Moloch"].processProposal(this.props.proposals[p].proposalIndex),800000,(receipt,err)=>{
                  console.log(receipt)
                  this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
                })
              }}>
              Process
            </Button>
          )
        }

        //            <Scaler config={{startZoomAt:900,origin:"0px 5px"}}>      </Scaler>

        console.log("DRAWING PROPOSALS","this.props.proposals[p].objectsArray",this.props.proposals[p].objectsArray)

        let inventoryArray = this.props.proposals[p].objectsArray ? this.props.proposals[p].objectsArray.map((object)=>{ if(object.type==1) return this.wrapEmoji(object.loadedEmoji,object._address) }) : []
        let buildableArray = this.props.proposals[p].objectsArray ? this.props.proposals[p].objectsArray.map((object)=>{ if(object.type==2||object.type==5) return this.wrapEmoji(object.loadedEmoji,object._address) }) : []
        let growableArray = this.props.proposals[p].objectsArray ? this.props.proposals[p].objectsArray.map((object)=>{ if(object.type==4) return this.wrapEmoji(object.loadedEmoji,object._address) }) : []
        let discoverableArray = this.props.proposals[p].objectsArray ? this.props.proposals[p].objectsArray.map((object)=>{ if(object.type==3) return this.wrapEmoji(object.loadedEmoji,object._address) }) : []

        let badgeSize = 7.5

        /*
        let costObj = (emoji)=>{
          return (
            <span>
              <Badge outer={this.props.utilityEmoji} inner={this.props.costToVote}
                size={6} color={Helpers.objectColor[1][0]} hightlight={Helpers.objectColor[1][1]}
              />
              {emoji}
            </span>
          )
        }
        */


        let propsalDisplay = (
          <span key={"proposal"+p} style={{marginBottom:20,whiteSpace:"nowrap"}}>

            {status} <Blockie address={this.props.proposals[p].proposer} config={{size:3}}/>


            <Badge outer={"🗃️"} inner={inventoryArray}
              size={badgeSize} color={Helpers.objectColor[1][0]} hightlight={Helpers.objectColor[1][1]}
            />
            <Badge outer={"🔨"} inner={buildableArray}
              size={badgeSize} color={Helpers.objectColor[2][0]} hightlight={Helpers.objectColor[2][1]}
            />
            <Badge outer={"🌱"} inner={growableArray}
              size={badgeSize} color={Helpers.objectColor[4][0]} hightlight={Helpers.objectColor[4][1]}
            />
            <Badge outer={"🧭"} inner={discoverableArray}
              size={badgeSize} color={Helpers.objectColor[3][0]} hightlight={Helpers.objectColor[3][1]}
            />
            <Badge outer={"💵"} inner={"$"+((this.props.proposals[p].tokenTribute/10**18).toFixed(2))}
              size={badgeSize} color="#444444" hightlight="#bbbbbb"
            />



            <Badge outer={"👍"} inner={this.props.proposals[p].yesVotes}
              size={badgeSize} color="#777777" hightlight="#dddddd"
            />
            <Badge outer={"👎"} inner={this.props.proposals[p].noVotes}
              size={badgeSize} color="#666666" hightlight="#cccccc"
            />

            <span style={{paddingLeft:10}}>
              {button}
            </span>
          </span>
        )


        proposals.push(
          <div key={"prop"+p} style={{marginLeft:"5%"}}>
            <Badge  outer={outer} inner={propsalDisplay}
              size={10} color="#444444" hightlight="#bbbbbb"
            />
          </div>
        )
      }
    }


    //console.log("@@@@@@@this.props.addingObjects ",this.props.addingObjects)
    let objectEmojis = []
    let objectAddressArray = []
    for(let a in this.props.addingObjects){
      //console.log("==-- do stuff with ",a,this.props.addingObjects[a])
      objectEmojis.push(
        <span onClick={async (e)=>{
          e.stopPropagation();
          this.props.setContractMenu(await this.props.loadContractForGenericObject(this.props.addingObjects[a].address),"")
        }}>
          {this.props.addingObjects[a].emoji}
        </span>
      )
      objectAddressArray.push(this.props.addingObjects[a].address)
    }

    let innerMoloch=(
      <span>
        <span style={{cursor:"pointer"}} onClick={()=>{
          console.log("ADDOBJECT")
          this.props.setMenu("addObject")
        }}>
          <Badge outer={"➕"} inner={objectEmojis}
            size={11} color="#666666" hightlight="#dddddd"
          />
        </span>

        <Badge outer={"💵"} inner={(
          <span>
            $<input
              style={{border:'none',verticalAlign:"top",marginTop:7,fontSize:30,width:90,backgroundColor:"#dddddd"}}
              type="number" placeholder="0.00" name="tribute" value={this.state.tribute} onChange={this.handleInput.bind(this)}
            />
          </span>
        )}
          size={11} color="#666666" hightlight="#dddddd"
        />

        <Button color={objectAddressArray.length>0?"green":"gray"} size={2} onClick={async ()=>{

            if(!this.props.inventory || !this.props.inventory[this.props.utilityEmoji] || this.props.inventory[this.props.utilityEmoji]<this.props.costToSubmitSubmitProposal){
              this.props.setLoaderByTransaction({},"Not enough "+this.props.utilityEmoji,5000)
              return;
            }

            if(objectAddressArray.length<=0){
              this.props.setLoaderByTransaction({},"No rules added yet (click the [+] button).",5000)
              return;
            }

            console.log("objectAddressArray",objectAddressArray)
            if(this.state.tribute){
              this.props.setLoader(true,5,"💰 Approving Tribute...")
              this.props.tx(this.props.contracts["StableCoin"].approve(this.props.contracts["Moloch"]._address,this.state.tribute*10**18),75000,(receipt,err)=>{
                if(err){
                  this.props.setLoaderByTransaction(receipt,err,4500)
                }else{
                  this.props.setLoader(true,5,"🗳️ Submit Proposal...")
                }
                this.props.tx(this.props.contracts["Moloch"].submitProposal(this.state.tribute*10**18,objectAddressArray,"No Comment"),500000,(receipt,err)=>{
                  console.log(receipt)
                  this.props.clearAddingObjects()
                  this.setState({tribute:""})
                  this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
                })
              })
            }else{
              this.props.setLoader(true,5,"🗳️ Submit Proposal...")
              this.props.tx(this.props.contracts["Moloch"].submitProposal(this.state.tribute*10**18,objectAddressArray,"No Comment"),500000,(receipt,err)=>{
                console.log(receipt)
                this.props.clearAddingObjects()
                this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
              })
            }


          }}>
          <Badge outer={this.props.utilityEmoji} inner={this.props.costToSubmitSubmitProposal}
            size={6} color={Helpers.objectColor[1][0]} hightlight={Helpers.objectColor[1][1]}
          /> Submit 🗳️
        </Button>

        <span style={{opacity:anySleeping?1:0.25}}>
          <Button color={"#c23128"} size={2} onClick={async ()=>{
            if(anySleeping){
              this.props.setLoader(true,5,"👹 RAGE QUIT!")
              this.props.tx(this.props.contracts["DAOG"].rageQuit(),3000000,(receipt,err)=>{
                this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
                console.log(receipt)
              })
            }
            }}>
            Rage Quit
          </Button>
        </span>

      </span>
    )

    //console.log("heightRatio",this.props.heightRatio)
    //

    let blocksIn = parseInt(this.props.block)

    return (
      <div key="footer" style={{zIndex:8,paddingTop:10,backgroundColor:"#111111",position:'fixed',bottom:0,left:0,width:"100%",height:(150+65*(this.props.openProposals))*this.props.heightRatio/100*1.13}}>

        <div style={{float:"right"}}>
          <Scaler config={{startZoomAt:1100,origin:"100% 50%"}}>
            <Badge outer={"🕰"} inner={blocksIn}
              size={8} color="#666666" hightlight="#dddddd"
            />
          <div style={{marginTop:5}}>
                <Loader key={"loader_block_"+blocksIn} {...this.props}/>
            </div>

          </Scaler>
        </div>


        <Scaler config={{startZoomAt:1100,origin:"0% -10%"}}>
          {proposals}
          <div style={{margin:15,marginTop:25}}>
          <Badge outer={"👹"} inner={innerMoloch}
            size={14} color="#c23128" hightlight="#edc7c4"
          />
          </div>
        </Scaler>

          {debugInfo}






      </div>
    )
  }
}
