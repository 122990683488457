import React, { Component } from "react";
import QrReader from "react-qr-reader";
import FileReaderInput from 'react-file-reader-input';
import qrimage from '../qrcode.png';


function base64ToBitmap(base64) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      context.drawImage(img, 0, 0);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      resolve({
        data: Buffer.from(imageData.data),
        width: canvas.width,
        height: canvas.height
      });
    };
    img.src = base64;
  });
}

let interval
class SendByScan extends Component {
  constructor(props){
    super(props)
    let defaultToLegacyMode = false
    if(!navigator||!navigator.mediaDevices){
      defaultToLegacyMode = true
    }
    this.state = {
      delay: 400,
      browser: "",
      legacyMode: defaultToLegacyMode,
      scanFail: false,
      isLoading: false,
      percent: 5,
    };
    this.handleScan = this.handleScan.bind(this)
  }
  stopRecording = () => this.setState({ delay: false });
  onImageLoad = data => {
    console.log("IMAGE LOAD",data)
    console.log(data)
  }
  handleScan = data => {
    console.log("DATA")
    console.log(data)

    if(data && data.indexOf("http")==0){
      this.setState({loading:true})
      setTimeout(()=>{
        //maybe they just scanned an address?
        window.location = data
      },100)
    }else if(data){
      this.setState({loading:true})
      setTimeout(()=>{
        //maybe they just scanned an address?
        window.location = "/"+data
      },100)
    }
  }
  chooseDeviceId = (a,b) => {
    console.log("chooseDeviceId ",a,b)
    console.log("choose",a,b)
  }
  handleError = error => {
    console.log("SCAN ERROR")
    console.error(error);
    this.setState({legacyMode:true})
    /*this.props.onError(()={
    });*/
  };
  onClose = () => {
    console.log("SCAN CLOSE")
    this.stopRecording();
    this.props.goBack();
  };
  componentDidMount(){
    interval = setInterval(this.loadMore.bind(this),750)
  }
  componentWillUnmount() {
    clearInterval(interval)
    this.stopRecording();
  }
  loadMore(){
    let newPercent = this.state.percent+3
    if(newPercent>100) newPercent=5
    this.setState({percent:newPercent})
  }
  legacyHandleChange(e, results){
    //this.props.changeView('reader')
    results.forEach(result => {
      const [e, file] = result;
      let reader = new FileReader();
      reader.onload = (e) => {
        console.log("")
        this.setState({imageData:e.target.result})

        Promise.all([
          base64ToBitmap(e.target.result),
          import('qrcode-reader')
        ])
          .then(([bitmap, { default: QrCode }]) => {
            console.log(QrCode);
            let qr = new QrCode();
            qr.callback = (err, value) => {
              this.setState({ isLoading: false });
              if (err) {
                setTimeout(() => {
                  console.log('FAILED TO SCAN!!!');
                  this.setState({ scanFail: err.toString() });
                  setTimeout(() => {
                    this.setState({ imageData: false });
                  }, 1500);
                  setTimeout(() => {
                    this.setState({ scanFail: false });
                  }, 3500);
                }, 1500);
              } else if (value && value.result) {
                this.handleScan(value.result);
              }
            };
            qr.decode(bitmap);
          })
          .catch(err => {
            alert('ERR1');
            console.error('ERR1', err);
            this.setState({ scanFail: err.toString() });
          });
      };
      reader.readAsDataURL(file);
    })
  }
  render() {

    if(this.state.loading){
      return (
        <div style={{textAlign:'center'}}>
          <div style={{width:"100%"}}>
            Loading...
          </div>
        </div>
      )
    }

    let displayedImage = ""
    if(this.state.imageData){
      displayedImage = (
        <img style={{position:"absolute",left:0,top:0,maxWidth:"100%",opacity:0.7}} src={this.state.imageData} />
      )
    }

    let failMessage = ""
    if(this.state.scanFail){
      failMessage = (
        <div style={{position:'absolute',left:0,top:0,zIndex:99,fontSize:24,color:"#FF0000",backgroundColor:"#333333",opacity:0.9,width:"100%",height:"100%",fontWeight:'bold'}}>
          <div style={{textAlign:"center",paddingTop:"15%"}}>
            <div style={{marginBottom:20}}><i className="fas fa-ban"></i></div>
          </div>
          <div style={{textAlign:"center",paddingTop:"25%"}}>
            <div>Please try again. Sorry, legacy mode *really* sucks. </div>

          </div>
          <div style={{textAlign:"center",padding:"10%",paddingTop:"15%",fontSize:16}}>
            <div>{this.state.scanFail}</div>
          </div>
        </div>
      )
    }

    let displayedReader = (
      <QrReader
        delay={this.state.delay}
        onError={this.handleError}
        onScan={this.handleScan}
        onImageLoad={this.onImageLoad}
        style={{ width: "100%" }}
        resolution={1200}
      />
    )
    if(this.state.legacyMode){
      displayedReader = (
        <div onClick={()=>{
          console.log("LOADING...")
          this.setState({isLoading:true})
        }}>
        <FileReaderInput as="binary" id="my-file-input" onChange={this.legacyHandleChange.bind(this)}>
        <div style={{position: 'absolute',zIndex:11,top:0,left:0,width:"100%",height:"100%",color:"#FFFFFF",cursor:"pointer"}}>
          <div style={{textAlign:"center",paddingTop:"15%"}}>
            <div style={{marginBottom:20}}><i className="fas fa-camera"></i></div>
            <img src={qrimage} style={{position:"absolute",left:"36%",top:"25%",padding:4,border:"1px solid #888888",opacity:0.25,maxWidth:"30%",maxHight:"30%"}} />
          </div>
          <div style={{textAlign:"center",paddingTop:"35%"}}>

            <div>Capture QR Code:</div>
              <div className="main-card card w-100" style={{backgroundColor:"#000000"}}>
                <div className="content ops row" style={{paddingLeft:"12%",paddingRight:"12%",paddingTop:10}}>
                    <button className="btn btn-large w-100" style={{backgroundColor:"#dddddd"}}>
                        <i className="fas fa-camera"  /> Take Photo
                    </button>
                </div>
              </div>
            </div>
            <div style={{textAlign:"center",paddingTop:"5%"}}>
              Lay QR flat and take a picture of it from a distance.
            </div>
        </div>

        </FileReaderInput>
        </div>
      )
    }


    return (
      <div style={{  position: "fixed",top:0,left:0,right:0,bottom:0,zIndex:5,margin:'0 auto !important',background:"#000000"}}>
        <div style={{ position: 'absolute',zIndex: 256,top:20,right:20,fontSize:80,paddingRight:20,color:"#FFFFFF",cursor:'pointer'}} onClick={this.onClose} >
          <div style={{zIndex:31,cursor:"pointer",fontSize:"10vw",position:'absolute',right:"4%",top:"3%"}} onClick={this.onClose} >
            ❌
          </div>
        </div>
        {displayedReader}
        <div style={{position: 'absolute',zIndex:11,bottom:20,fontSize:12,left:20,color:"#FFFFFF",opacity:0.333}}>
          {navigator.userAgent} - {JSON.stringify(navigator.mediaDevices)}
        </div>
        {displayedImage}
        {failMessage}
      </div>
    );
  }
}

export default SendByScan;
