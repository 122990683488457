import React from 'react';
import { Button, Blockie, Scaler } from "dapparatus"
import Badge from './Badge'
import Helpers from "../helpers.js"

const shadowColor = "#dddddd"//"#4981cd"
const secondColor = "#aaaaaa"//"#61aae2"
const shadowAmount = 80

let interval

export default class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: 1,
      lastComment: ""
    };
  }
  componentDidMount(){
    interval = setInterval(this.loadMore.bind(this),250)
  }
  componentWillUnmount(){
    clearInterval(interval)
  }
  loadMore(){
    if(this.props.seconds==-1){
      this.setState({percent:100})
    }else{
      let seconds = 5
      if(this.props.seconds){
        seconds = this.props.seconds
      }
      let newPercent = seconds?(this.state.percent+100/(seconds*1000/250)):100
      if(newPercent>100) newPercent=100
      if(this.state.lastComment!=this.props.comment){
        newPercent=5
        this.setState({lastComment:this.props.comment,percent:newPercent})
      }else{
        this.setState({percent:newPercent})
      }
    }

  }

  render() {
    return (
      <div key="loader">
        <div style={{paddingTop:"2%",width:"80%",height:1,marginLeft:"10%"}}>
          <div style={{width:this.state.percent+"%",height:1,backgroundColor:secondColor,boxShadow:"0 0 "+shadowAmount/40+"px "+shadowColor+", 0 0 "+shadowAmount/30+"px "+shadowColor+", 0 0 "+shadowAmount/20+"px "+shadowColor+", 0 0 "+shadowAmount/10+"px #ffffff, 0 0 "+shadowAmount/5+"px "+shadowColor+", 0 0 "+shadowAmount/3+"px "+shadowColor+", 0 0 "+shadowAmount/1+"px "+shadowColor+""}}>
          </div>
          <div style={{color:shadowColor,fontSize:"2vw",paddingTop:"3%",textAlign:'center'}}>
            {this.props.comment}
          </div>
        </div>
      </div>
    )
  }
}
