import React from 'react';
import { Blockie, Scaler} from "dapparatus"

let poll
export default class Players extends React.Component {
  /*componentDidMount(){
    poll = setInterval(this.poll.bind(this),1555)
  }
  componentWillUnmount(){
    clearInterval(poll)
  }
  async poll(){

  }*/
  render() {

    let players = []
    for(let p in this.props.players){
      //console.log("PLAYER:",this.props.players[p])
      players.push(
        <div key={"player"+p} style={{padding:10,whiteSpace:"nowrap"}}>
          <Blockie
            address={this.props.players[p].playerAddress}
            config={{size:3}}
          /> {this.props.players[p].playerAddress} {this.props.players[p].ready?"✅":"⏹"}
        </div>
      )
    }


    return (
      <div>
        <Scaler config={{startZoomAt:1000,origin:"0px 0px"}}>
        <div style={{color:"#DDDDDD",padding:30}}>
          Players: {this.props.playerCount}
        </div>
        {players}
        </Scaler>
      </div>
    )
  }
}
