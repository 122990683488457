import React from 'react';
import { Blockie, Scaler} from "dapparatus"
import Helpers from "../helpers.js"
import Badge from './Badge'

let poll
export default class VictoryReport extends React.Component {
  /*componentDidMount(){
    poll = setInterval(this.poll.bind(this),1555)
  }
  componentWillUnmount(){
    clearInterval(poll)
  }
  async poll(){

  }*/
  render() {
      console.log("this.props",this.props)
    console.log("this.props.players",this.props.players)


    let players = []
    for(let p in this.props.players){
      if(this.props.players[p].victoryInventory&&this.props.players[p].victoryInventory>0){
        //console.log("PLAYER:",this.props.players[p])
        players.push(
          <span key={"player"+p} style={{padding:5,whiteSpace:"nowrap"}}>
            <Badge outer={(
              <span>
                <span style={{paddingRight:4}}>
                  <Blockie
                    address={this.props.players[p].playerAddress}
                    config={{size:3}}
                  />
                </span>
                {this.props.victoryObjectEmoji}
              </span>
            )} inner={this.props.players[p].victoryInventory}
              size={10} color={Helpers.objectColor[this.props.victoryObjectType][0]} hightlight="#DDDDDD"
            />

        </span>
        )
      }

    }


    return (
      <div>
        <Scaler config={{startZoomAt:1000,paddingTop:20,origin:"0px 0px"}}>
        {players}
        </Scaler>
      </div>
    )
  }
}
