import React from 'react';
import { Button, Blockie, Events, Scaler } from "dapparatus"
import Badge from './Badge'
import Helpers from "../helpers.js"

import hexagonalgrid from "../hexagonalgrid.png"
import squaregrid from "../squaregrid.png"

const queryString = require('query-string');

var QRCode = require('qrcode.react');

const queryParams = queryString.parse(window.location.search);
console.log("queryParams",queryParams);
let queryObjects

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      daogs:[],
      buyIn:queryParams.buyIn,
      name:queryParams.name,
      environment:queryParams.environment?queryParams.environment:"Greenfield",
      molochPeriod: queryParams.molochPeriod?queryParams.molochPeriod:15,
      molochVoting: queryParams.molochVoting?queryParams.molochVoting:5,
      molochGrace: queryParams.molochGrace?queryParams.molochGrace:2,
      victoryAmount: queryParams.victoryAmount?queryParams.victoryAmount:1,
      grid:queryParams.grid?queryParams.grid:"Hexagonal",
    }
  }
  componentDidMount(){
    this.init()
  }
  async init(){
    queryObjects = {}
    if(queryParams.rules){
      let victoryArray = queryParams.rules.split(",")
      for(let v in victoryArray){
        let loaded = await this.props.loadContractForGenericObject(victoryArray[v])
        if(!queryObjects["rules"]) queryObjects["rules"] = []
        queryObjects["rules"].push(
          {
            address:loaded._address,
            emoji:loaded.loadedEmoji,
          }
        )
      }
    }
    if(queryParams.victory){
      let victoryArray = queryParams.victory.split(",")
      for(let v in victoryArray){
        let loaded = await this.props.loadContractForGenericObject(victoryArray[v])
        if(!queryObjects["victory"]) queryObjects["victory"] = []
        queryObjects["victory"].push(
          {
            address:loaded._address,
            emoji:loaded.loadedEmoji,
          }
        )
      }
    }
  }
  handleInput(e){
    let update = {}
    update[e.target.name] = e.target.value
    this.setState(update)
  }
  handleOptionChange(e){
    this.setState({
      environment: e.target.value
    });
  }
  handleGridOptionChange(e){
    this.setState({
      grid: e.target.value
    });
  }
  render() {

    let qrSize = Math.min(document.documentElement.clientWidth,512)-90

    let daogs = []
    for(let d in this.state.daogs){
      daogs.push(
        <div style={{padding:10}}>
          <Button size="2" color={"blue"} onClick={()=>{
            window.location = "/"+this.state.daogs[d]._address
          }}>
            <Blockie address={this.state.daogs[d]._address} />
            {this.state.daogs[d]._address.substring(0,6)+"... "+this.props.web3.utils.hexToUtf8(this.state.daogs[d].nameLoaded)}
          </Button>
        </div>
      )
    }

    let addressStrings = []

    let objectEmojis = []
    let objectAddressArray = []
    for(let a in this.props.addingObjects){
      console.log("this.props.addingObjects",a,this.props.addingObjects)
      if(!objectEmojis[a]) objectEmojis[a] = []
      if(!objectAddressArray[a]) objectAddressArray[a] = []
      if(!addressStrings[a]) addressStrings[a] = ""
      for(let i in this.props.addingObjects[a]){
        if(addressStrings[a]!="") addressStrings[a] += ","
        addressStrings[a] += this.props.addingObjects[a][i].address
        objectEmojis[a].push(
          <span onClick={async (e)=>{
            e.stopPropagation();
            this.props.setContractMenu(await this.props.loadContractForGenericObject(this.props.addingObjects[a][i].address),"")
          }}>
            {this.props.addingObjects[a][i].emoji}
          </span>
        )
        objectAddressArray[a].push(this.props.addingObjects[a][i].address)
      }
    }


    for(let a in queryObjects){
      console.log("queryObjects",a,queryObjects)
      if(!objectEmojis[a]) objectEmojis[a] = []
      if(!objectAddressArray[a]) objectAddressArray[a] = []
      if(!addressStrings[a]) addressStrings[a] = ""
      for(let i in queryObjects[a]){
        if(addressStrings[a]!="") addressStrings[a] += ","
        addressStrings[a] += queryObjects[a][i].address
        objectEmojis[a].push(
          <span onClick={async (e)=>{
            e.stopPropagation();
            this.props.setContractMenu(await this.props.loadContractForGenericObject(queryObjects[a][i].address),"")
          }}>
            {queryObjects[a][i].emoji}
          </span>
        )
        objectAddressArray[a].push(queryObjects[a][i].address)
      }
    }

    console.log("addressStrings",addressStrings)
    console.log("objectEmojis",objectEmojis)
    console.log("objectAddressArray",objectAddressArray)

    const SHOW_CREATE_GAME_BUTTON = true;
    const SHOW_CREATE_GAME_ON_FRONT_PAGE = false;


    let templateLink = window.location.origin+"/create"
    if(this.state.environment) templateLink += "?environment="+encodeURI(this.state.environment)
    if(this.state.grid) templateLink += "&grid="+encodeURI(this.state.grid)
    if(this.state.name) templateLink += "&name="+encodeURI(this.state.name)
    if(this.state.buyIn) templateLink += "&buyIn="+encodeURI(this.state.buyIn)
    if(addressStrings['rules']) templateLink += "&rules="+encodeURI(addressStrings['rules'])
    if(addressStrings['victory']) templateLink += "&victory="+encodeURI(addressStrings['victory'])
    if(this.state.victoryAmount) templateLink += "&victoryAmount="+encodeURI(this.state.victoryAmount)
    if(this.state.molochPeriod) templateLink += "&molochPeriod="+encodeURI(this.state.molochPeriod)
    if(this.state.molochVoting) templateLink += "&molochVoting="+encodeURI(this.state.molochVoting)
    if(this.state.molochGrace) templateLink += "&molochGrace="+encodeURI(this.state.molochGrace)


    let daogBuilder = (
      <div>

      <div style={{fontSize:"2vw",padding:"5%",textAlign:"center"}}>
        (wait here for games to be created)
      </div>

        <div style={{fontSize:"6vw",padding:"10%",textAlign:"center"}}>
          - private alpha testing -
        </div>



        <div style={{padding:"2%",paddingBottom:"5%",backgroundColor:"#FFFFFF",textAlign:"center"}}>
          <QRCode value={this.props.account} size={qrSize}/>
          <div style={{fontSize:"3vw"}}>
            {this.props.account}
          </div>
        </div>
        <div style={{padding:"25%",paddingBottom:"25%",backgroundColor:"#FFFFFF",textAlign:"center"}}>

        </div>

        {this.props.scanButton}
      </div>
    )
    if(SHOW_CREATE_GAME_ON_FRONT_PAGE || window.location.href.indexOf("create")>0){
      daogBuilder = (
        <div>
                <div style={{fontSize:"2vw",padding:"2%",marginTop:"5%",backgroundColor:"#666666",textAlign:"center"}}>
                  DEPLOY 🐶 DAOG:
                </div>

                <div style={{fontSize:"2vw",padding:"2%",backgroundColor:"#888888",textAlign:"center",color:"#333333"}}>
                  <Scaler config={{startZoomAt:800,origin:"0px 0px"}}>
                  <div>

                    <div style={{padding:20}}>
                      <div style={{paddingBottom:20}}>
                      Environment:
                      </div>
                      <Scaler config={{startZoomAt:800,origin:"50% 50%"}}>
                        <span style={{whiteSpace:'nowrap',paddingLeft:40}}><input checked={this.state.environment==="Greenfield"} onChange={this.handleOptionChange.bind(this)} type="radio" name="game" value="Greenfield" /> <span style={{padding:20,backgroundColor:"#3e833c",color:"#FFFFFF",fontSize:30}}>🙂 Greenfield ⛺️</span></span>
                        <span style={{whiteSpace:'nowrap',paddingLeft:40}}><input checked={this.state.environment==="Desert"} onChange={this.handleOptionChange.bind(this)} type="radio" name="game" value="Desert" /> <span style={{padding:20,backgroundColor:"#bcad80",color:"#FFFFFF",fontSize:30}}>🦂 Desert 🌵</span></span>
                        <span style={{whiteSpace:'nowrap',paddingLeft:40}}><input checked={this.state.environment==="Space"} onChange={this.handleOptionChange.bind(this)} type="radio" name="game" value="Space" /> <span style={{padding:20,backgroundColor:"#111111",color:"#EEEEEE",fontSize:30}}>⚡️ Space 🛰️</span></span>
                      </Scaler>
                    </div>

                    <div style={{padding:20}}>
                      <div style={{paddingBottom:20}}>
                      Grid:
                      </div>
                      <Scaler config={{startZoomAt:800,origin:"50% 50%"}}>
                        <span style={{whiteSpace:'nowrap',paddingLeft:40}}><input checked={this.state.grid==="Square"} onChange={this.handleGridOptionChange.bind(this)} type="radio" name="grid" value="Square" /> <img src={squaregrid} /></span>
                        <span style={{whiteSpace:'nowrap',paddingLeft:40}}><input checked={this.state.grid==="Hexagonal"} onChange={this.handleGridOptionChange.bind(this)} type="radio" name="grid" value="Hexagonal" /> <img src={hexagonalgrid} /></span>
                      </Scaler>
                    </div>


                    <div style={{padding:20}}>
                      Name:
                      <span style={{paddingLeft:40}}>
                      <input
                        style={{verticalAlign:"middle",fontSize:38,width:350,textAlign:"center",backgroundColor:"#dddddd"}}
                        type="text" name="name" value={this.state.name} onChange={this.handleInput.bind(this)}
                      />
                      </span>
                    </div>

                    <div style={{padding:20}}>
                      Buy in: <Badge outer={"💵"} inner={(
                        <span>
                          $<input
                            style={{border:'none',verticalAlign:"middle",fontSize:34,width:130,backgroundColor:"#dddddd"}}
                            type="number" placeholder="0.00" name="buyIn" value={this.state.buyIn} onChange={this.handleInput.bind(this)}
                          />
                        </span>
                      )}
                        size={11} color="#666666" hightlight="#dddddd"
                      />
                    </div>

                    <div style={{padding:20}}>
                      Game Mechanics:
                      <span style={{cursor:"pointer"}} onClick={()=>{
                        console.log("ADDOBJECT")
                        this.props.setMenu("addObject","rules")
                      }}>
                        <Badge outer={"➕"} inner={objectEmojis['rules']}
                          size={11} color="#666666" hightlight="#dddddd"
                        />
                      </span>
                    </div>

                    <div style={{padding:20}}>Victory:
                    <span style={{cursor:"pointer"}} onClick={()=>{
                      console.log("ADDOBJECT")
                      this.props.setMenu("addObject","victory")
                    }}>
                      <Badge outer={"➕"} inner={objectEmojis['victory']}
                        size={11} color="#666666" hightlight="#dddddd"
                      />
                    </span> amount:<input
                      style={{verticalAlign:"middle",fontSize:38,width:80,textAlign:"center",backgroundColor:"#dddddd"}}
                      type="text" name="victoryAmount" value={this.state.victoryAmount} onChange={this.handleInput.bind(this)}
                    />
                    </div>


                    <div style={{padding:20}}>👹
                    period:<input
                      style={{verticalAlign:"middle",fontSize:38,width:80,textAlign:"center",backgroundColor:"#dddddd"}}
                      type="text" name="molochPeriod" value={this.state.molochPeriod} onChange={this.handleInput.bind(this)}
                    />s
                    voting:<input
                      style={{verticalAlign:"middle",fontSize:38,width:80,textAlign:"center",backgroundColor:"#dddddd"}}
                      type="text" name="molochVoting" value={this.state.molochVoting} onChange={this.handleInput.bind(this)}
                    />
                    grace:<input
                      style={{verticalAlign:"middle",fontSize:38,width:80,textAlign:"center",backgroundColor:"#dddddd"}}
                      type="text" name="molochGrace" value={this.state.molochGrace} onChange={this.handleInput.bind(this)}
                    />
                    </div>

                    <Button size="3" color={"green"} onClick={()=>{

                          let victoryAmount = "0"
                          if(this.state.victoryAmount){
                            victoryAmount = this.state.victoryAmount
                          }
                          console.log("victoryAmount",victoryAmount)

                          let victoryObject = "0x0000000000000000000000000000000000000000"
                          if( objectAddressArray && objectAddressArray["victory"] && objectAddressArray["victory"][0]){
                            victoryObject = objectAddressArray["victory"][0]
                          }
                          console.log("victoryObject",victoryObject)

                        if(!this.state.name){
                          this.props.setLoaderByTransaction({},"Please enter a name.",2000)

                        }else if(victoryObject=="0x0000000000000000000000000000000000000000" || victoryAmount=="0"){
                          this.props.setLoaderByTransaction({},"Please enter a victory condition.",2000)

                        }else{
                          this.props.setLoader(true,5,"Deploying 👹 Moloch...")
                          //console.log("CONTRACT",this.props.contracts.Moloch._contract)
                          this.props.tx(this.props.contracts.Moloch._contract.deploy({
                            data:"0x"+require("../contracts/Moloch.bytecode.js"),
                            arguments:[
                              this.state.molochPeriod,
                              this.state.molochVoting,
                              this.state.molochGrace,
                              1
                            ]
                          }),8500000,(receipt,err)=>{
                            this.props.setLoaderByTransaction(receipt,err,0)
                            console.log("~~~~~~ Moloch DEPLOY FROM DAPPARATUS:",receipt)
                            if(receipt.contractAddress){

                              let molochAddress = receipt.contractAddress
                              console.log("MOLOCH CONTRACT DEPLOYED:",molochAddress)
                              let buyIn = (parseFloat(this.state.buyIn)*10**18)
                              if(!buyIn) buyIn=0
                              console.log(buyIn)
                              let hexName = "0x00"
                              if(this.state.name) hexName = this.props.web3.utils.toHex(this.state.name)
                              if(hexName=="0x0"){
                                hexName="0x00"
                              }
                              console.log("hexName",hexName)
                              let rules = []
                              if(objectAddressArray && objectAddressArray["rules"]){
                                rules = objectAddressArray["rules"]
                              }
                              console.log("rules",rules)


                              let args = [
                                hexName,
                                5,
                                5,
                                ""+buyIn,
                                require("../contracts/StableCoin.address.js"),
                                require("../contracts/"+this.state.environment+".address.js"),//require("../contracts/Space.address.js")
                                molochAddress,
                                require("../contracts/History.address.js"),
                                rules,
                                victoryObject,
                                victoryAmount,
                                require("../contracts/Maths.address.js"),
                                this.state.grid==="Hexagonal"
                              ]
                              console.log("ARGS",args)
                              console.log("CONTRACT",this.props.contracts.DAOG._contract)
                              this.props.setLoader(true,5,"Deploying 🐶 DAOG...")
                              this.props.tx(this.props.contracts.DAOG._contract.deploy({
                                data:"0x"+require("../contracts/DAOG.bytecode.js"),
                                arguments: args
                              }),8500000,(receipt,err)=>{
                                this.props.setLoaderByTransaction(receipt,err,0)
                                console.log("~~~~~~ DAOG DEPLOY FROM DAPPARATUS:",receipt)
                                if(receipt.contractAddress){
                                  let daogAddress = receipt.contractAddress
                                  console.log("DAOG CONTRACT DEPLOYED:",daogAddress)
                                  console.log("SETTING DAOG ON MOLOCH...")
                                  let molochContract = this.props.contractLoader("Moloch",molochAddress);
                                  this.props.setLoader(true,5,"🔗 Linking Contracts...")
                                  this.props.tx(molochContract.setDAOG(daogAddress),250000,(receipt,err)=>{
                                    this.props.setLoaderByTransaction(receipt,err,0)
                                    console.log("~~~~~~ setDAOG FROM DAPPARATUS:",receipt)

                                    this.props.setLoader(true,5,"⏳ Loading...")
                                    window.location = "/"+daogAddress
                                  })
                                }
                              })
                            }
                          })
                        }


                      }}>
                      Deploy
                    </Button>
                  </div>
                  </Scaler>
                </div>


                <div style={{fontSize:"2vw",padding:"2%",marginTop:"5%",backgroundColor:"#666666",textAlign:"center"}}>
                  Game Template Link:
                </div>

                <div style={{fontSize:"1vw",padding:"2%",backgroundColor:"#888888",textAlign:"center",color:"#333333"}}>
                  <div style={{width:"50%",margin:"auto",wordWrap:'break-word'}}>

                    {templateLink}
                  </div>
                </div>
                <div style={{padding:"2%",paddingBottom:"5%",backgroundColor:"#FFFFFF",textAlign:"center"}}>
                  <QRCode value={templateLink} size={qrSize}/>
                </div>
        </div>
     )
   } else if(SHOW_CREATE_GAME_BUTTON){
      daogBuilder = (
        <div>
          <div style={{fontSize:"6vw",padding:"10%",textAlign:"center"}}>
            - private alpha testing -
          </div>
          <div style={{fontSize:"6vw",padding:"10%",textAlign:"center"}}>
            <Button size="3" color={"blue"} onClick={()=>{
              window.location = "/create"
            }}>Create Game</Button>
          </div>


          <div style={{padding:"2%",paddingBottom:"5%",backgroundColor:"#FFFFFF",textAlign:"center"}}>
            <QRCode value={this.props.account} size={qrSize}/>
            <div style={{fontSize:"3vw"}}>
              {this.props.account}
            </div>
          </div>
          <div style={{padding:"25%",paddingBottom:"25%",backgroundColor:"#FFFFFF",textAlign:"center"}}>

          </div>

          {this.props.scanButton}
        </div>
      )
    }



    let eventParser = ""
    if(this.props.contracts && typeof this.props.block != "undefined" && typeof this.props.account != "undefined" ){
      eventParser = (
        <Events
          config={{hide:true}}
          contract={this.props.contracts["History"]}
          eventName={"AddDAOG"}
          block={this.props.block}
          onUpdate={async (eventData,allEvents)=>{
            console.log("DAOG:",eventData)
            let currentDaogs = this.state.daogs
            let tempDAOG = this.props.contractLoader("DAOG",eventData.DAOG);
            let phase = await tempDAOG.phase().call()
            tempDAOG.nameLoaded = await tempDAOG.name().call()
            if(phase==1&&eventData.blockNumber > this.props.block-60){
              currentDaogs.push(tempDAOG)
              this.setState({daogs:currentDaogs})
            }
          }}
        />
      )
    }

    return (
      <div style={{widht:"100%",height:"100%",backgroundColor:"#FFFFFF"}}>
        <div style={{fontSize:"8vw",padding:"3%",textAlign:"center"}}>
          🐶 DAOG
        </div>
        <div style={{fontSize:"2vw",padding:"2%",textAlign:"center"}}>
          {daogs} {eventParser}
        </div>


        {daogBuilder}


        <div style={{position:"fixed",left:0,top:0,fontSize:"2vw",paddingTop:"2%",color:"#333333",opacity:0.5}}>
          <Scaler config={{startZoomAt:800,origin:"0px 0px"}}>
            <Badge outer={"🏦"} inner={"$"+(Math.round(this.props.tokens/10**15)/1000).toFixed(2)}
              size={10} color="#444444" hightlight="#bbbbbb"
            />
          </Scaler>
        </div>

      </div>
    )
  }
}
