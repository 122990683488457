import React from 'react';
import { Metamask, Gas, ContractLoader, Transactions, Events, Scaler, Blockie, Address, Button } from "dapparatus"
import Players from './Players'
import Badge from './Badge'
import Header from './Header'
import Helpers from "../helpers.js"

import hexagonalgrid from "../hexagonalgrid.png"
import squaregrid from "../squaregrid.png"

var QRCode = require('qrcode.react');

export default class Lobby extends React.Component {
  componentDidMount() {
    console.log("Checking in with History contract...")
    this.props.tx(this.props.contracts["History"].checkin(this.props.contracts["DAOG"]._address,this.props.contracts["Game"]._address),75000,async (receipt,err)=>{
      console.log("Checkin Receipt",receipt)

    })

    this.props.setLoader(true,5,"👌 Approving...")
    this.props.tx(this.props.contracts["StableCoin"].approve(this.props.contracts["DAOG"]._address,this.props.buyIn),100000,async (receipt,err)=>{
      console.log(receipt)
      //this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
      let reveal = this.props.web3.utils.keccak256(this.props.web3.utils.randomHex(32))

      let cachedReveal = localStorage.getItem("reveal")
      if(cachedReveal){
        reveal = cachedReveal
      } else {
        localStorage.setItem("reveal",reveal)
      }

      console.log("reveal",reveal)
      let commit = await this.props.contracts["DAOG"].getHash(reveal).call()
      console.log("commit",commit,"reveal",reveal)

      this.props.setLoader(true,5,"👉 Joining...")
      this.props.tx(this.props.contracts["DAOG"].join(commit,0),250000,(receipt,err)=>{
        console.log(receipt)
        this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
        this.props.setLoader(true,5,"👍 Ready...")
        this.props.tx(this.props.contracts["DAOG"].ready(true),50000,(receipt,err)=>{
          console.log(receipt)
          this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
        })
      })
    })
  }
  render() {

    let joinButton = ""
    let readyStatus = ""
    if(this.props.player)
    {

      let buyIn = (<Badge outer={"💵"} inner={"$"+(Math.round(  this.props.buyIn/10**15)/1000).toFixed(2)}
        size={6} color="#444444" hightlight="#bbbbbb"
      />)

      if(this.props.player.playerAddress=="0x0000000000000000000000000000000000000000"){
        //they have not joined yet, show join button
        joinButton = ""/*(
          <Button color={"green"} size={"2"} onClick={async ()=>{

            this.props.setLoader(true,5,"👌 Approving...")
            this.props.tx(this.props.contracts["StableCoin"].approve(this.props.contracts["DAOG"]._address,this.props.buyIn),100000,async (receipt,err)=>{
              console.log(receipt)
              //this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
              let reveal = this.props.web3.utils.keccak256(this.props.web3.utils.randomHex(32))
              console.log("reveal",reveal)
              let commit = await this.props.contracts["DAOG"].getHash(reveal).call()
              console.log("commit",commit,"reveal",reveal)
              localStorage.setItem("reveal",reveal)
              this.props.setLoader(true,5,"👉 Joining...")
              this.props.tx(this.props.contracts["DAOG"].join(commit,0),250000,(receipt,err)=>{
                console.log(receipt)
                this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
              })
            })

            }}>
            {buyIn} Join <Badge outer={this.props.utilityEmoji} inner={this.props.startingBalance}
              size={6} color={Helpers.objectColor[1][0]} hightlight={Helpers.objectColor[1][1]}
            />
          </Button>
        )*/
      }else{
        if(this.props.enoughPlayersReady){
          readyStatus = (
            <div>
              <Button color={"green"} size={"2"} onClick={()=>{
                  this.props.setLoader(true,5,"🚀 Starting...")
                  this.props.tx(this.props.contracts["DAOG"].start(localStorage.getItem("reveal"),0),3500000,(receipt,err)=>{
                    console.log(receipt)
                    localStorage.setItem("reveal","")
                    this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
                  })
                }}>
                Start
              </Button>
            </div>
          )
        }else if(this.props.player.ready){
          readyStatus = (
            <div>
              ✅ Ready
            </div>
          )
        }else{
          readyStatus = ""/*(
            <div>
              <Button color={"green"} size={"2"} onClick={()=>{
                  this.props.setLoader(true,5,"👍 Ready...")
                  this.props.tx(this.props.contracts["DAOG"].ready(true),50000,(receipt,err)=>{
                    console.log(receipt)
                    this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
                  })
                }}>
                Ready
              </Button>
            </div>
          )*/
        }
      }
    }


    let gameDisplay = (
      <span style={{whiteSpace:'nowrap',paddingLeft:40}}><span style={{padding:20,backgroundColor:this.props.backgroundColor.replace("0x","#"),color:"#FFFFFF",fontSize:30}}>{this.props.utilityEmoji} {this.props.gameName}</span></span>
    )


    let qrSize = Math.min(document.documentElement.clientWidth,512)-90

    //add         {this.props.scanButton} for a scanner here
    return (
      <div key="lobby" style={{padding:"5%",paddingTop:"25%"}}>
        <Header {...this.props} />



        <div style={{float:'right',clear:'both'}}>

          {gameDisplay}
        </div>
        <div style={{float:'right',clear:'both',opacity:0.5}}>
          <img src={this.props.hexagonal?hexagonalgrid:squaregrid} style={{maxWidth:90}}/>
        </div>

        {joinButton}
        {readyStatus}

        <Players
          {...this.props}
        />

        <div style={{marginTop:"10%",padding:"2%",paddingBottom:"5%",backgroundColor:"#FFFFFF",textAlign:"center"}}>
          <div style={{fontSize:"1.5vw",padding:5}}></div>
          <QRCode value={window.location.href} size={qrSize}/>
          <div style={{fontSize:"1.2vw",padding:5}}>
            {window.location.href}
            {qrSize}
          </div>
        </div>

        <div style={{textAlign:'center',padding:"5%",paddingTop:"9%"}}>
          <Button size="3" color={"blue"} onClick={async ()=>{
            window.location = "/"
          }}>
            Leave
          </Button>
        </div>
      </div>
    )
  }
}
