import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Metamask, Gas, ContractLoader, Transactions, Events, Scaler, Blockie, Address, Button } from "dapparatus"
import Players from './Players'
import Header from './Header'

var QRCode = require('qrcode.react');

const TIP_AMOUNT = 0.01 * 10**18


export default class Recap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {tips:[]}
  }
  componentDidMount(){
    this.init()
  }
  async init(){
    this.setState({
      winner: (await this.props.contracts['DAOG'].winner().call()).toLowerCase(),
    })
  }
  render() {

    let qrSize = Math.min(document.documentElement.clientWidth,512)-90

    if(!this.state.winner){
      return (
        <div>
          loading...
        </div>
      )
    }

    let winnerInfo = ""
    if(this.props.account == this.state.winner && this.props.phase==3){
      winnerInfo = (
        <div style={{zIndex:999}}>
          <Button key="collect" color={"green"} size={"2"} onClick={async ()=>{
              this.props.setLoader(true,5,"💵 Collecting...")
              //submitVote(uint256 proposalIndex, uint8 uintVote)
              this.props.tx(this.props.contracts["DAOG"].collect(5),2000000,(receipt,err)=>{
                this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
                console.log(receipt)
              })
            }}>
            Collect
          </Button>
        </div>
      )
    }

    let actions = ["👏","🤩","😘","🤑","😐","😒","😬","🤮","😮","😭","😤","😡"]

    let tipButtons = actions.map((action)=>{
      return (
        <Col sm={1}>
          <Button key={"action"+action} color={"blue"} size={"2"} onClick={async ()=>{
              this.props.setLoader(true,5,action)
              this.props.tx(this.props.contracts["History"].tip(this.props.contracts["DAOG"]._address,this.props.contracts["Game"]._address,this.props.web3.utils.toHex(action)),100000,"0x",TIP_AMOUNT,async (receipt,err)=>{
                console.log("Checkin Receipt",receipt)
                this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
              })
            }}>
            {action}
          </Button>
        </Col>
      )
    })

    console.log("this.state.tips",this.state.tips)
    let tips = []
    for(let t in this.state.tips){
      tips.push(
        <span style={{marginRight:15,padding:3,backgroundColor:'#AAAAAA'}}>
          <Blockie
            address={this.state.tips[t].player}
            config={{size:2}}
          /> {this.props.web3.utils.hexToUtf8(this.state.tips[t].emoji)}
        </span>
      )
    }


    let rulesString = ""
    for(let o in this.props.objects){
      if(rulesString!="") rulesString+=","
      rulesString+=this.props.objects[o]._address
    }

    let templateLink = window.location.origin+"/create"
    templateLink += "?environment="+encodeURI(this.props.gameName)
    templateLink += "&grid="+(this.props.hexagonal?"Hexagonal":"Square")
    if(this.props.name) templateLink += "&name="+encodeURI(this.props.name)
    if(this.props.buyIn) templateLink += "&buyIn="+encodeURI(this.props.buyIn/10**18)
    if(rulesString) templateLink += "&rules="+encodeURI(rulesString)
    if(this.props.victoryObjectAddress) templateLink += "&victory="+encodeURI(this.props.victoryObjectAddress)
    if(this.props.victoryAmount) templateLink += "&victoryAmount="+encodeURI(this.props.victoryAmount)
    if(this.props.periodDuration) templateLink += "&molochPeriod="+encodeURI(this.props.periodDuration)
    if(this.props.votingPeriodLength) templateLink += "&molochVoting="+encodeURI(this.props.votingPeriodLength)
    if(this.props.gracePeriodLength) templateLink += "&molochGrace="+encodeURI(this.props.gracePeriodLength)


    return (
      <div key="lobby" style={{padding:100}}>
        <Header {...this.props} {...this.state}/>

        <Players
          {...this.props}
        />
        <div>
          winner:
          <Blockie
            address={this.state.winner}
            config={{size:3}}
          />
        </div>
        {winnerInfo}

        <div style={{textAlign:'center',padding:"5%",paddingTop:"10%"}}>
          <Button size="3" color={"blue"} onClick={async ()=>{
            window.location = "/"
          }}>
            Done
          </Button>
        </div>


        <div style={{textAlign:'center',padding:"5%",paddingTop:"15%"}}>
          <Container>
            <Row>
              {tipButtons}
            </Row>
          </Container>
          <div>
            {tips}
          </div>
        </div>

        <Events
          config={{hide:true}}
          contract={this.props.contracts['History']}
          eventName={"Tip"}
          block={this.props.block}
          filter={{DAOG:this.props.contracts['DAOG']._address}}
          onUpdate={(eventData,allEvents)=>{
            console.log("EVENT DATA:",eventData)
            this.setState({tips:allEvents})
          }}
        />



        <div style={{fontSize:"2vw",padding:"2%",marginTop:"5%",backgroundColor:"#666666",textAlign:"center"}}>
          Game Template Link:
        </div>

        <div style={{fontSize:"1vw",padding:"2%",backgroundColor:"#888888",textAlign:"center",color:"#333333"}}>
          <div style={{width:"50%",margin:"auto",wordWrap:'break-word'}}>

            {templateLink}
          </div>
        </div>
        <div style={{padding:"2%",paddingBottom:"5%",backgroundColor:"#FFFFFF",textAlign:"center"}}>
          <QRCode value={templateLink} size={qrSize}/>
        </div>
      </div>
    )
  }
}
