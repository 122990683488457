import React from 'react';
import Hexagon from 'react-hexagon'
import { Metamask, Gas, ContractLoader, Transactions, Events, Scaler, Blockie, Address, Button } from "dapparatus"
import Header from './Header'
import Footer from './Footer'
import Map from './Map'
import MenuMap from './MenuMap'
import Helpers from "../helpers.js"
import Badge from "./Badge"

var QRCode = require('qrcode.react');

let timeouts

let reveals = {}

let contractLoadPromises = {}

const BLANK_EMOJI = "0x0000000000000000"

export default class Game extends React.Component {
  constructor(props) {
    super(props);
    let backgroundColorString = this.props.backgroundColor.replace("0x","")
    //let backgroundColorRed = parseInt(backgroundColorString[0]+backgroundColorString[1],16)
    //let backgroundColorGreen = parseInt(backgroundColorString[2]+backgroundColorString[3],16)
    //let backgroundColorBlue = parseInt(backgroundColorString[4]+backgroundColorString[5],16)
    //let colorVariation = 10
    let land = []
    for(let x=0;x<this.props.size;x++){
      if(!land[x]) land[x] = []
      for(let y=0;y<this.props.size;y++){
        /*let variationColor = (backgroundColorRed+Math.round(Math.random()*colorVariation)-Math.round(Math.random()*colorVariation)).toString(16)+
          (backgroundColorGreen+Math.round(Math.random()*colorVariation)-Math.round(Math.random()*colorVariation)).toString(16)+
          (backgroundColorBlue+Math.round(Math.random()*colorVariation)-Math.round(Math.random()*colorVariation)).toString(16)
        */
        let variationColor = this.props.tileColors[Math.floor(Math.random()*this.props.tileColors.length)]
        //console.log("this.props.tileColors",this.props.tileColors)
        if(!land[x][y]) land[x][y] = {color:variationColor}
      }
    }
    this.state = {
      land: land,
      proposals: [],
      centerX: -1,
      centerY: -1,
      openProposals: 0,
      menu: false,
      currentInfluence: 0,
      eventFilter: {
        //one day this will have to do with player proximity or something
        playerAddress: this.props.account
      }
    }

    if(!this.props.player||this.props.player.playerAddress=="0x0000000000000000000000000000000000000000"){
      console.log("SETTING TO PUBLIC VIEW")
      this.state.centerX = Math.floor(this.props.size/2)
      this.state.centerY = Math.floor(this.props.size/2)
      this.state.eventFilter = false
    }
  }

  getContractForEmoji(emoji){
    if(this.props.objects){
      for(let o=0;o<this.props.objects.length;o++){
        if(this.props.objects[o] && this.props.objects[o].loadedEmoji == emoji){
          return this.props.objects[o]
        }
      }
    }
  }

  async landPoll() {

    if(this.props.player && this.props.player.influence>0){
      if(this.state.currentInfluence!=this.props.player.influence){
        this.setState({currentInfluence:this.props.player.influence},()=>{
          this.forEachTileInMyInfluence(async (localx,localy,x,y)=>{
            this.updateLand(x,y)
          })
        })

      }
    }


    let emojiToContract = {}
    //console.log("Looking through my land...")
    let landUpdate = this.state.land
    let update = false
    for(let x=0;x<this.props.size;x++){
      for(let y=0;y<this.props.size;y++){
        if(this.state.land[x][y] && this.state.land[x][y].objectType && this.state.land[x][y].owner == this.props.account && this.state.land[x][y].emoji){
          //console.log("TILE NEEDS TO LOAD CONTRACT FOR ",x,y,this.state.land[x][y])
          let contract = this.getContractForEmoji(this.state.land[x][y].emoji)
          if(contract){
            //console.log("CONTRACT",contract)
            if(typeof contract.onCollect == "function"){
              landUpdate[x][y].lastBlock = parseInt(await contract.lastBlock(this.props.contracts["DAOG"]._address,x,y).call())
              let currentBlock = await this.props.web3.eth.getBlockNumber()
              let blocksPassed = currentBlock - landUpdate[x][y].lastBlock
              let earningsLength = await contract.earningsLength().call()
              let earnings = []
              //console.log("Found ",earningsLength," earnings...")
              for(let e=0;e<earningsLength;e++){
                let thisEarning = await contract.earnings(e).call()
                //console.log("thisEarning",thisEarning)
                if(landUpdate[x][y].lastBlock==0){
                  thisEarning.toGo = -1
                }else if(thisEarning.blocks<=blocksPassed){
                  thisEarning.toGo = 0
                }else{
                  thisEarning.toGo = thisEarning.blocks - blocksPassed
                }
                earnings.push({
                  emoji: this.props.web3.utils.hexToUtf8(thisEarning.emoji),
                  emojiBytes: thisEarning.emoji,
                  amount: thisEarning.amount,
                  blocks: thisEarning.blocks,
                  toGo: thisEarning.toGo
                })
              }
              if(earningsLength>0){
                landUpdate[x][y].earnings = earnings
                update=true
              }
            }

            if(typeof contract.growToEmoji == "function"){
              landUpdate[x][y].blocksToGrow = await contract.blocksToGrow().call()
              landUpdate[x][y].blocksGrown = await contract.blocksGrown(this.props.contracts["DAOG"]._address,x,y).call()
              landUpdate[x][y].growToEmoji = await contract.growToEmoji().call()
              //blocksGrown(daog,x,y)>=blocksToGrow
              if(landUpdate[x][y].blocksGrown>=landUpdate[x][y].blocksToGrow){
                landUpdate[x][y].toGo = 0
              }else{
                landUpdate[x][y].toGo = landUpdate[x][y].blocksToGrow - landUpdate[x][y].blocksGrown
              }
              update=true
            }
          }
        }
      }
    }
    if(update){
      this.setState({land:landUpdate})
    }
  }

  componentDidMount(){
    //this is hacky, but attempt to scroll to the middle AFTER everything is loaded
    setTimeout(()=>{
      console.log("Scrolling based on height ",window.innerHeight)
      let scrollToHeight = Math.round((window.innerHeight*0.3)/2)
      console.log("scrollToHeight",scrollToHeight)
      let scrollToWidth = Math.round(window.innerWidth/4)
      window.scrollTo(scrollToWidth,scrollToHeight)
    },1000)
    setInterval(this.landPoll.bind(this),2555)
    setTimeout(this.landPoll.bind(this),555)
    setTimeout(this.landPoll.bind(this),1555)
  }

  forEachTileInMyInfluence(fn){
    let influence = this.props.size
    if(this.props.player && this.props.player.influence>0){
      influence = this.props.player.influence
    }
    let offsetX = this.state.centerX-Math.floor(influence/2)
    let offsetY = this.state.centerY-Math.floor(influence/2)

    for(let x=0;x<influence;x++){
      for(let y=0;y<influence;y++){

        let adjustedX = offsetX+x
        let adjustedY = offsetY+y
        if(adjustedX < 0) adjustedX+=this.props.size
        if(adjustedY < 0) adjustedY+=this.props.size
        if(adjustedX>=this.props.size) adjustedX-=this.props.size
        if(adjustedY>=this.props.size) adjustedY-=this.props.size
        fn(x,y,adjustedX,adjustedY)
      }
    }
  }

  async loadProposal (eventData,allEvents){
    console.log("SUBMIT PROPOSAL EVENT DATA:",eventData)

    let currentProposals = this.state.proposals
    currentProposals[eventData.proposalIndex] = await this.props.contracts["Moloch"].proposalQueue(eventData.proposalIndex).call()
    currentProposals[eventData.proposalIndex].objects = await this.props.contracts["Moloch"].getProposalsObjects(eventData.proposalIndex).call()
    currentProposals[eventData.proposalIndex].myVote = await this.props.contracts["Moloch"].getMemberProposalVote(this.props.account,eventData.proposalIndex).call()

    currentProposals[eventData.proposalIndex].proposalIndex = eventData.proposalIndex

    //replace addresses with contracts
    if(currentProposals[eventData.proposalIndex].objects){
      let contractArray = []
      for(let o=0;o<currentProposals[eventData.proposalIndex].objects.length;o++){
        console.log("LOOKING TO LOAD THESE OBJECTS ",currentProposals[eventData.proposalIndex].objects)
        //eventually you will need to use the dynamic contract loader, but for now just load them by finding them
        //
        for(let c in this.props.contracts){
          if(this.props.contracts[c]._address==currentProposals[eventData.proposalIndex].objects[o]){
            contractArray[o]=this.props.contracts[c];
            contractArray[o].loadedEmoji = this.props.web3.utils.hexToUtf8(await contractArray[o].emoji().call())
            contractArray[o].type = await contractArray[o].objectType().call()
            break;
          }
        }
        if(!contractArray[o]){
          let address = currentProposals[eventData.proposalIndex].objects[o]

          if(!contractLoadPromises[address]){
            console.log("Loading contract for address: "+address+"...")
            contractLoadPromises[address] = this.props.loadContractForGenericObject(address)
          }else{
            console.log("Can't find contract "+address+" but it is already loading...")
          }
          contractArray[o] = await contractLoadPromises[address]
        }
      }
      currentProposals[eventData.proposalIndex].objectsArray=contractArray
    }


    let openProposals = 0
    for(let p=0;p<currentProposals.length;p++){
      if(!currentProposals[p].processed){
        openProposals++
      }
    }

    this.setState({
      proposals:currentProposals,
      openProposals
    },async ()=>{
      //console.log("UPDATED PROPOSALS ("+this.state.openProposals+"):",this.state.proposals)
      this.props.loadObjects()
    })
  }

  async landHandler(eventData,allEvents,local){
    //console.log("EVENT DATA:",eventData)
    let currentLand = this.state.land
    if(!currentLand[eventData.x]) currentLand[eventData.x] = []
    currentLand[eventData.x][eventData.y] = await this.props.contracts["DAOG"].land(eventData.x,eventData.y).call()

    if(currentLand[eventData.x][eventData.y].emoji){
      currentLand[eventData.x][eventData.y].emojiBytes = currentLand[eventData.x][eventData.y].emoji
      currentLand[eventData.x][eventData.y].emoji = this.props.web3.utils.toUtf8(currentLand[eventData.x][eventData.y].emojiBytes)
    }
    if(currentLand[eventData.x][eventData.y].owner){
      currentLand[eventData.x][eventData.y].owner=currentLand[eventData.x][eventData.y].owner.toLowerCase()
    }

    //console.log(")))))) currentLand",currentLand)

    let tilesOwned = 0
    for(let x=0;x<this.props.size;x++){
      for(let y=0;y<this.props.size;y++){
        if(currentLand[x]&&currentLand[x][y]&&currentLand[x][y].owner==this.props.account){
          tilesOwned++
        }
      }
    }

    this.setState({
      events:allEvents,
      land:currentLand,
      tilesOwned:tilesOwned
    },async ()=>{
      // metadata 0 means it is possibly their home base? might need to come up with something better eh?
      if(eventData.metadata==0 && this.state.centerX==-1 && this.state.centerY==-1){
        this.setState({
          centerX:parseInt(eventData.x),
          centerY:parseInt(eventData.y),
          eventFilter:false
        })
      }else{
        //mark distances of tiles for exploration
        this.updateLand(eventData.x,eventData.y)
      }
    })
  }

  updateLand(cx,cy){
    let currentLand = this.state.land
    let tilesOwned = 0
    this.forEachTileInMyInfluence(async (localx,localy,x,y)=>{
      let fromLand = currentLand[cx][cy]
      if(fromLand.owner == this.props.account){
        if(currentLand && currentLand[x] && currentLand[x][y]){
          if(typeof currentLand[x][y].distance == "undefined"){
            currentLand[x][y].distance=this.props.size
          }
          let distanceToThisTileCheck = distanceOnchain(cx,cy,x,y,this.props.size,this.props.hexagonal)
          let distanceToThisTile = distanceToThisTileCheck//await this.props.contracts["Maths"].distance(eventData.x,eventData.y,x,y,this.props.size,this.props.hexagonal).call();
          //if(distanceToThisTileCheck!=distanceToThisTile){
          //  alert("DIFFERENCE AT "+eventData.x+","+eventData.y+" to "+x+","+y+" (is "+distanceToThisTileCheck+" should be "+distanceToThisTile+")")
          //}
          if(currentLand[x][y].distance>distanceToThisTile){
            currentLand[x][y].distance=distanceToThisTile
            currentLand[x][y].nearest = [cx,cy]
          }
        }
      }
    })
    this.setState({land:currentLand})
  }

  render() {



    //console.log("this.props.player",this.props.player)
    /*if(!this.props.player||this.props.player.playerAddress=="0x0000000000000000000000000000000000000000"){
      return (
        <div>
          <Map {...this.props} />
        </div>
      )
    }*/



    let eventLoader = ""
    let extraEventLoader = ""
    if(this.props.contracts && typeof this.props.block != "undefined" && typeof this.state.eventFilter != "undefined" && this.props.account)
    {
      //console.log("EVENT LOADER IS GOOD!!!!")
      eventLoader = (
        <div>
          <Events
            config={{hide:true}}
            contract={this.props.contracts["DAOG"]}
            eventName={"SignalUpdate"}
            block={this.props.block}
            filter={this.state.eventFilter}
            onUpdate={this.landHandler.bind(this)}
          />
        </div>

      )

      extraEventLoader = (
       <div>
         <Events
           config={{hide:true}}
           contract={this.props.contracts["Moloch"]}
           eventName={"SubmitProposal"}
           block={this.props.block}
           onUpdate={this.loadProposal.bind(this)}
         />
         <Events
           config={{hide:true}}
           contract={this.props.contracts["Moloch"]}
           eventName={"SubmitVote"}
           block={this.props.block}
           onUpdate={this.loadProposal.bind(this)}
         />
         <Events
           config={{hide:true}}
           contract={this.props.contracts["Moloch"]}
           eventName={"ProcessProposal"}
           block={this.props.block}
           onUpdate={this.loadProposal.bind(this)}
         />
       </div>
      )
    }



    if(this.state.centerX==-1 || this.state.centerY==-1){
      return <div>loading {eventLoader}</div>
    }

    let width = getWidth()
    let height = getHeight()

    let influence = this.props.size
    if(this.props.player && this.props.player.influence>0){
      influence = this.props.player.influence
    }

    let gameQR = ""
    let qrSize = 128

    let tileSize = Math.round(Math.min(width*3/2,height)/influence)
    if(influence==this.props.size){
      gameQR = (
        <div style={{position:"fixed",right:25,bottom:25,padding:10,backgroundColor:"#FFFFFF"}}>
          <QRCode value={window.location.href} size={qrSize}/>
        </div>

      )
      tileSize = Math.round(tileSize/1.75)
    }else{
      tileSize = Math.round(tileSize*.9)
    }

    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    if(isAndroid) {
      //some android devices looked really weird so lets force a shrink down here
      tileSize = Math.min(tileSize,75)
    }

    let extraHint = ""

    let board = []
    let totalInfluenceCount = 0
    this.forEachTileInMyInfluence((localx,localy,x,y)=>{
      if(!this.state.land||!this.state.land[x]){
        return
      }
      //console.log(x,y,offsetX,offsetY,adjustedX,adjustedY)
      let tile = this.state.land[x][y]

      //console.log(tile)
      let opacity = 0.25
      let onClick = null

      if(tile&&tile.owner&&tile.owner!="0x0000000000000000000000000000000000000000"){
        opacity = 1
      }

      if(influence==0){
        opacity=1
      }


      let fontSize = Math.round(tileSize*0.85*2/3)
      ///console.log("fontSize",fontSize,"tileSize",tileSize)
      let paddingTop = 0

      let backgroundColor
      if(tile && tile.color){
        if(tile.color!="0x000000"){
          backgroundColor = "#"+tile.color.replace("0x","")
        }else{
          backgroundColor = "#"+this.props.backgroundColor.replace("0x","")
        }
      }
      let emoji = ""

      if(tile && tile.emoji){
        emoji = tile.emoji
      }

      extraHint=""
      if(tile && tile.owner && tile.owner.toLowerCase() == this.props.account && tile.emojiBytes==BLANK_EMOJI){
        emoji = (
          <div style={{padding:'13%',opacity:0.5}}>
            🔨
          </div>
        )


        console.log("tilesOwned: ",this.state.tilesOwned)
        if(this.state.tilesOwned==1){
          extraHint = (
            <div style={{marginLeft:-323,marginTop:-90,width:500,height:200}}>
              <Badge outer={"Click 🔨 to Build"} inner={"➡️"}
              size={8} color={"#FFFFFF"} hightlight={"#FFFFFF"}
              />
            </div>

          )

        }

        emoji = (
          <div style={{padding:'13%',opacity:0.5}}>
            🔨
          </div>
        )



        opacity = 1
        fontSize = Math.round(tileSize*0.35)
        ///console.log("fontSize",fontSize,"tileSize * 0.35",tileSize)
        onClick = false /*()=>{
          //TILECLICK
          //console.log("ARGS",""+x,""+y,this.props.web3.utils.toHex("⛺️"))
          if(!this.state.startedCampHardCodedForNow){
            this.props.tx(
              this.props.contracts["DAOG"].build(""+x,""+y,this.props.web3.utils.toHex("⛺️")),
              2000000,(result)=>{
                console.log("CALLBACK",result)
                this.setState({startedCampHardCodedForNow:true})
              }
            )
          }else{
            if(this.props.contracts["DAOG"] && this.props.contracts["DAOG"].eventFilter){
              this.props.tx(
                  this.props.contracts["DAOG"].eventFilter(""+x,""+y,this.props.web3.utils.toHex("🌱")),
                2000000,(result)=>{
                  console.log("CALLBACK",result)
                }
              )
            }
          }
        }*/
      }



      //check to see if this tile is 'explorable'
      let exploreButton = ""
      let exploreOffset = false
      if(tile && tile.distance==1 && (!tile.owner||tile.owner=="0x0000000000000000000000000000000000000000") && this.props.player.influence>0){
        exploreOffset = true
        emoji = (
          <div style={{position:"relative"}}>
            <div style={{padding:'13%',opacity:0.5}}>
              🔭
            </div>
            <div style={{position:"absolute",left:"35%",top:"45%",opacity:0.77}}>
              <Scaler config={{startZoomAt:1000,origin:"0% 100%",adjustedZoom:1}}>
              <Badge outer={this.props.utilityEmoji} inner={this.props.costToExplore}
              size={3} color={Helpers.objectColor[1][0]} hightlight={Helpers.objectColor[1][1]}
              />
              </Scaler>
            </div>
          </div>
        )
        opacity = 0.5
        fontSize = tileSize*0.35
        onClick= async ()=>{

          if(!this.props.inventory || !this.props.inventory[this.props.utilityEmoji] || this.props.inventory[this.props.utilityEmoji]<this.props.costToExplore){
            this.props.setLoaderByTransaction({},"Not enough "+this.props.utilityEmoji,5000)
            return;
          }

          if(!timeouts) {
            timeouts = []
          }
          if(!timeouts[x]){
            timeouts[x]= []
          }

          timeouts[x][y] = setTimeout(()=>{
            let currentLand = this.state.land
            currentLand[x][y].loading = false
            currentLand[x][y].randoming = false
            this.setState({land:currentLand})
          },12000)

          //(uint256 x, uint256 y, uint256 fromX, uint256 fromY)
          let reveal = this.props.web3.utils.keccak256(this.props.web3.utils.randomHex(32))
          let nonce = parseInt(this.props.web3.utils.keccak256(x+"_"+y))%255;
          console.log("reveal",reveal)
          let commit = await this.props.contracts["DAOG"].getHash(reveal).call()
          console.log("commit",commit,"reveal",reveal)

          reveals[nonce] = reveal
          console.log("reveals",x,y,nonce,"set to",reveals[nonce])

          let currentLand = this.state.land
          currentLand[x][y].loading = true
          this.setState({land:currentLand})
          this.props.setLoader(true,5,"🧭 Exploring...")
          this.props.tx(
            this.props.contracts["DAOG"].commitToRandomness(commit,nonce),
            1000000,(result,err)=>{
              console.log("&&&&&&&&&&&&&&&&&&&&&&&&&CALLBACK",result)
              console.log("NEAREST:",tile.nearest)
              if(err){
                this.props.setLoaderByTransaction(result,err,4500)
              }else{
                this.props.setLoader(true,5,"🎲 Revealing...")
              }

              let currentLand = this.state.land
              currentLand[x][y].randoming = true
              this.setState({land:currentLand})

              console.log("revealing nonce", nonce, reveals[nonce])
              this.props.tx(
                this.props.contracts["DAOG"].discover(""+x,""+y,""+tile.nearest[0],""+tile.nearest[1],reveals[nonce],nonce),
                5000000,(result,err)=>{
                  this.props.setLoaderByTransaction(result,err,err?4500:1500)
                  clearTimeout(timeouts[x][y])
                  console.log("DISCOVER CALLBACK",result)
                }
              )
              localStorage.removeItem("reveal_"+x+"_"+y+"_"+nonce)
            }
          )
        }
      }


      if(tile && tile.loading){
        emoji = (
          <div style={{padding:'13%',opacity:0.3}}>
            ⌛
          </div>
        )
      }
      if(tile && tile.randoming){
        emoji = (
          <div style={{padding:'13%',opacity:0.7}}>
            🎲
          </div>
        )
      }

      if(emoji&&!onClick){
        onClick = ()=>{
          this.props.setMenu("map",[x,y])
        }
      }

      let cursor = "not-allowed"
      if(typeof onClick == "function"){
        cursor = "pointer"
      }

      let extraInfo = ""
      if(this.props.debug && tile&&tile.nearest){
        extraInfo = (
          <div style={{position:'absolute',left:"25%",opacity:0.7,fontSize:12,color:"#FFFFFF"}}>
            {tile.distance} {x},{y} [{tile.nearest[0]},{tile.nearest[1]}]
          </div>
        )
      }

      let growToEmoji = ""
      if(tile && tile.growToEmoji){
        if(tile.blocksGrown >= tile.blocksToGrow-1){
          growToEmoji = (
            <div key={"growto_"+x+"_"+y} style={{position:'absolute',left:"10%",bottom:0,fontSize:"2vw",margin:4}}>
              {"🔼"}
            </div>
          )
        }else{
          growToEmoji = (
            <div key={"growto_"+x+"_"+y} style={{position:'absolute',left:"10%",bottom:0,fontSize:"2vw",margin:4}}>
              {Helpers.blockClock(tile.toGo-1)}
            </div>
          )
        }
      }


      let earnings = []

      if(tile && tile.earnings){
        for(let e in tile.earnings){
          if(tile.earnings[e].emoji){
            let emoji
            if(tile.earnings[e].toGo=="-1"){
              emoji = "▶️"
            }else if(tile.earnings[e].toGo>1){
              emoji = Helpers.blockClock(tile.earnings[e].toGo-1)
            }else{
              emoji = tile.earnings[e].emoji
            }
            earnings.push(
              <div key={"earning_"+x+"_"+y+"_"+e} style={{cursor:"grab",zIndex:6,position:'absolute',left:"10%",bottom:0+16*e,fontSize:"2vw",margin:4}} onClick={()=>{
                  this.props.setLoader(true,5,emoji+" Collecting...")
                  //submitVote(uint256 proposalIndex, uint8 uintVote)
                  this.props.tx(this.props.contracts["DAOG"].collect(x,y),1000000,(receipt,err)=>{
                    this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
                    console.log(receipt)
                  })
                }}>
                {emoji}
              </div>
            )
          }
        }
      }
      let ownerDisplay
      if(tile && tile.owner){

          ownerDisplay = (
            <div key={"owner_"+x+"_"+y} style={{position:'absolute',right:"5%",top:"35%",fontSize:"2vw",margin:4}}>
              <Scaler config={{startZoomAt:800,origin:"100% 0%",adjustedZoom:1}}>
                <Blockie size={2} address={tile.owner}/>
              </Scaler>
            </div>
          )

          if(tile.owner!=this.props.account){
            opacity*=0.6
          }
      }

      let yOffsetForBigScreen = 0
      let xWrapAroundForBigScreen = 0
      if(influence == this.props.size){
        let fullWidth = (this.props.size*tileSize)+((this.props.size*tileSize)/2)
        if(fullWidth<getWidth()){
          yOffsetForBigScreen = Math.round((getWidth()-fullWidth))
        }
        if(this.props.size-1-x<y/2){
          xWrapAroundForBigScreen = (this.props.size*tileSize)
        }
      }

      // hexagons will offset the x depending on how far down the row they are...
      let xOffset = 0
      let yOffset = 0
      let squareBackgroundColor = backgroundColor
      let squareClick = onClick
      let squareCursor = cursor
      let hexagon = ""
      if(this.props.hexagonal){
        xOffset = Math.floor(localy*Math.floor(tileSize)/2)
        yOffset = Math.floor((localy*Math.floor(tileSize)/2)*0.3)
        squareBackgroundColor = false
        squareClick = false
        squareCursor = false
        hexagon = (
          <div>
            <div style={{zIndex:-1,position:'absolute', left:0, top:0, width:tileSize, height:tileSize}}>
              <Hexagon style={{zIndex:-1,strokeWidth:10,stroke:'#000000', fill:backgroundColor}}>
              </Hexagon>
            </div>
            <div style={{zIndex:this.props.menu?-1:5,position:'absolute',left:"5%",top:"15%",width:"90%",height:"85%",cursor}} onClick={onClick}>
            </div>
          </div>

        )
      }


      board.push(
        <div key={"tile_"+x+"_"+y+"_"+(totalInfluenceCount++)} onClick={squareClick} style={{cursor:squareCursor,backgroundColor:squareBackgroundColor,opacity,fontSize,textAlign:'center', position: 'absolute',left: Math.floor(localx*Math.floor(tileSize)+xOffset)+yOffsetForBigScreen-xWrapAroundForBigScreen,top:Math.floor(localy*Math.floor(tileSize))-yOffset+this.props.topPadding,width:Math.ceil(tileSize+0.01),height:Math.ceil(tileSize+0.01)}}>
          <div style={{position:'relative',paddingTop:"22%"}}>
            {hexagon}
            {extraInfo}
            {emoji}
            {earnings}
            {growToEmoji}
            {ownerDisplay}
            {extraHint}
          </div>
        </div>
      )
    })
//    console.log("fontSize",fontSize)
    //console.log("totalInfluenceCount",totalInfluenceCount)

    let BOTTOM_PADDING = 100 + this.state.openProposals*50

    board.push(
      <div key={"bottompad"} style={{backgroundColor:"#000000",position: 'absolute',left:0,top:Math.floor(influence*Math.floor(tileSize))+this.props.topPadding,width:width,height:BOTTOM_PADDING}}>
      </div>
    )
    let menu=""
    if(this.props.menuMode=="map"){
      menu = (
        <MenuMap {...this.props} {...this.state} topPadding={this.props.topPadding} />
      )
    }

    return (
      <div key="game" style={{padding:30}}>
        <Header {...this.props} topPadding={this.props.topPadding}/>
        {board}
        {eventLoader}
        <Footer heightRatio={this.props.heightRatio} {...this.props} {...this.state} setMenu={this.props.setMenu}/>
        {extraEventLoader}
        {menu}
        {gameQR}
      </div>
    )
  }
}


function distanceOnchain(x,y,x2,y2,size,hexagonal){
  let xdiff=0
  x=parseInt(x)
  y=parseInt(y)
  x2=parseInt(x2)
  y2=parseInt(y2)
  size=parseInt(size)

  let xaround = false
  if(x>x2){
    xdiff = x-x2;
    if(xdiff>size/2){
      xdiff = x2+(size)-x;
      xaround = true
    }
  }else{
    xdiff = x2-x;
    if(xdiff>size/2){
      xdiff = x+(size)-x2;
    }else{
      xaround = true
    }
  }
  let ydiff=0
  let yaround = false
  if(y>y2){
    ydiff = y-y2;
    if(ydiff>size/2){
      ydiff = y2+(size)-y;
      yaround=true
    }
  }else{
    ydiff = y2-y;
    if(ydiff>size/2){
      ydiff = y+(size)-y2;
    }else {
      yaround=true
    }
  }

  let diffSoFar = Math.floor(Math.sqrt(xdiff*xdiff + ydiff*ydiff));

  if(hexagonal && (xdiff==ydiff) && (xaround==yaround)){
    diffSoFar++
  }

  return diffSoFar
}

function getWidth(){
  return window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth
      || 0;
}

function getHeight(){
  return window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight
      || 0;
}
