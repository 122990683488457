import React, { Component } from 'react';
import './App.css';
import { Dapparatus, Gas, ContractLoader, Transactions, Events, Scaler, Blockie, Address, Button, PrivateKeyCatcher } from "dapparatus"
import FrontPage from './components/FrontPage'
import Lobby from './components/Lobby'
import Game from './components/Game'
import Recap from './components/Recap'
import Web3 from 'web3';
import MenuObjects from './components/MenuObjects'
import MenuContract from './components/MenuContract'
import Helpers from "./helpers.js"
import Loader from './components/Loader'
import Scanner from './components/Scanner'
import scan from './scan.png';
import wallet from './wallet.png';
import posed from 'react-pose';

let loadCache = {}

let PROVIDER = "https://dai.poa.network"

let contractLoadPromises = {}

/*const queryString = require('query-string');
const queryParams = queryString.parse(window.location.search);
if(queryParams){
  console.log("queryParams",queryParams);
  if(queryParams.provider){
    localStorage.setItem("provider",queryParams.provider)
  }
}
try{
  let preferredProvider = localStorage.getItem("provider")
  if(preferredProvider){
    console.log("--> Using preferredProvider: ",preferredProvider)
    PROVIDER = preferredProvider
  }
}catch(e){}*/


if(window.location.href.indexOf("localhost")>=0 || window.location.href.indexOf("10.0.0.107")>=0){
  PROVIDER = "http://0.0.0.0:8545"
}




var QRCode = require('qrcode.react');

let TOP_PADDING = 110
let HEIGHT_RATIO = 1

let loadingContract = {}

const FadeDownBox = posed.div({
  visible: {
    opacity: 0.95,zIndex:32,paddingTop:10,backgroundColor:"#111111",position:'fixed',bottom:0,left:0,width:"100%",height:"20%",
    transition: { type: 'spring', stiffness: 100 }
  },
  hidden: {
    opacity: 0,zIndex:32,paddingTop:10,backgroundColor:"#111111",position:'fixed',bottom:0,left:0,width:"100%",height:"0%",
    transition: { type: 'spring', stiffness: 24 }
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      web3: false,
      account: false,
      gwei: 1.2345,
      doingTransaction: false,
      size: 0,
      players: [],
      addingObjects: [],
      selection: [],
      debug: localStorage.getItem("debug")=="yes",
      DAOG:false,
      myMechanics: [],
      scanner: false,
      loader: true,
      comment: "⏳ Loading...",
      seconds: 10
    }
  }
  componentDidMount(){
    this.setState({comment:"📡 Connecting..."})
    console.log("checking for DOAG",window.location.pathname.length)
    if(window.location.pathname.length==43){
      this.setState({DAOG:window.location.pathname.substr(1)},()=>{
        console.log("THE DAOG IS ",this.state.DAOG)
      })
      setInterval(this.poll.bind(this),2777)
      setInterval(this.playerPoll.bind(this),5555)
    }
  }
  setLoader(visible,seconds,comment){
    this.setState({loader:visible,seconds:seconds,comment:comment})
  }
  setLoaderByTransaction(transaction,err,closeAfter){
    if(err){
      this.setState({loader:true,seconds:-1,comment:"😢 Failure: "+err.toString().replace("Error: Returned error: VM Exception while processing transaction: revert ","")})
    }
    else if(transaction.status){
      this.setState({loader:true,seconds:-1,comment:"😃 Success"})
    }
    if(closeAfter){
      setTimeout(()=>{
        this.setState({loader:false,seconds:0,comment:""})
      },closeAfter)
    }
  }
  setMenu(mode,selection){
    this.setState({menu:true,selection:selection,menuMode:mode})
  }
  addMyMechanics(address){
    let myMechanics = this.state.myMechanics
    myMechanics.push(address)
    this.setState({myMechanics})
  }
  closeMenu(){
    console.log("CLOSE MENU")
    this.setState({menu:false,selection:[],menuMode:false})
  }
  setContractMenu(selectedContract,contractMenuReturns){
    this.setState({contractMenu:true,selectedContract:selectedContract,contractMenuReturns:contractMenuReturns})
  }
  closeContractMenu(contractMenuReturns){
    console.log("CLOSE Contract MENU")
    this.setState({contractMenu:false,selectedContract:"",contractMenuReturns:contractMenuReturns})
  }
  handleInput(e){
    let update = {}
    update[e.target.name] = e.target.value
    this.setState(update)
  }
  async poll() {
    if(this.state.contracts){
      let inventory = []
      let {objects} = this.state
      console.log("polling...")
      for(let o in objects){
        if(objects[o]&&objects[o].typeName&&objects[o].loadedEmoji&&objects[o].typeName=="Inventory"){
          inventory[objects[o].loadedEmoji] = await objects[o].inventory(this.state.contracts['DAOG']._address,this.state.account).call()
        }
      }
      this.setState(
        {
          size: parseInt(await this.state.contracts["DAOG"].size().call()),
          currentPeriod:await this.state.contracts['Moloch'].getCurrentPeriod().call(),
          tokens:await this.state.contracts['StableCoin'].balanceOf(this.state.account).call(),
          allowance: await this.state.contracts['StableCoin'].allowance(this.state.account,this.state.contracts["DAOG"]._address).call(),
          phase: await this.state.contracts['DAOG'].phase().call(),
          pot:await this.state.contracts['StableCoin'].balanceOf( this.state.contracts['DAOG']._address).call(),
          player:await this.state.contracts['DAOG'].players(this.state.account).call(),
          inventory: inventory
        }
      )
    }
  }
  async getContractByAddress(address) {
    for(let c in this.state.contracts){
      if(this.state.contracts[c]._address==address){
        return this.state.contracts[c]
      }
    }
    if(!contractLoadPromises[address]){
      contractLoadPromises[address] = this.loadContractForGenericObject(address)
    }
    return await contractLoadPromises[address]
  }
  async loadContractForGenericObject(address) {
    if(loadCache[address]) return loadCache[address]
    let loadedContract = this.state.contractLoader("Object",address)
    let type = await loadedContract.objectType().call()
    let typeName = Helpers.objectType[type]
    console.log("=====>>",address,type,typeName)
    if(typeName){
      let specificObject = this.state.contractLoader(typeName,address)
      loadedContract = specificObject
      loadedContract.type = await loadedContract.objectType().call()
      loadedContract.typeName = Helpers.objectType[loadedContract.type]
    }
    loadedContract.loadedEmoji = this.state.web3.utils.hexToUtf8(await loadedContract.emoji().call())

    if(typeof loadedContract.plantedOn == "function"){
      loadedContract.plantedOnEmoji = this.state.web3.utils.hexToUtf8(await loadedContract.plantedOn().call())
      loadedContract.loadedGrowToEmoji = this.state.web3.utils.hexToUtf8(await loadedContract.growToEmoji().call())
      loadedContract.loadedBlocksToGrow = await loadedContract.blocksToGrow().call()
      loadedContract.loadedCosts = []
      if(typeof loadedContract.costsLength =="function"){
        let costlen = await loadedContract.costsLength().call()
        for(let c=0;c < costlen;c++){
          let loadedCost = await loadedContract.costs(c).call()
          let cleanerObj = {
            emoji: this.state.web3.utils.hexToUtf8(loadedCost.emoji),
            amount: loadedCost.amount
          }
          loadedContract.loadedCosts[c] = cleanerObj
        }
      }
    }
    if(typeof loadedContract.builtOn == "function"){
      loadedContract.builtOnEmoji = this.state.web3.utils.hexToUtf8(await loadedContract.builtOn().call())
      if(typeof loadedContract.builtNear == "function"){
        loadedContract.builtNearEmoji = this.state.web3.utils.hexToUtf8(await loadedContract.builtNear().call())
      }
      loadedContract.loadedCosts = []
      if(typeof loadedContract.costsLength =="function"){
        let costlen = await loadedContract.costsLength().call()
        for(let c=0;c < costlen;c++){
          let loadedCost = await loadedContract.costs(c).call()
          let cleanerObj = {
            emoji: this.state.web3.utils.hexToUtf8(loadedCost.emoji),
            amount: loadedCost.amount
          }
          loadedContract.loadedCosts[c] = cleanerObj
        }
      }
    }
    loadCache[address] = loadedContract
    return loadedContract
  }
  async playerPoll() {
    if(this.state.contracts){
      let playerCount = await this.state.contracts['DAOG'].playerCount().call()
      let players = []
      let victoryObject
      let {objects} = this.state
      for(let o in objects){
        if(objects[o]&&objects[o]._address&&objects[o]._address.toLowerCase()==this.state.victoryObjectAddress.toLowerCase()){
          victoryObject = objects[o]
        }
      }
      console.log("PLAYER POLL victoryObject",victoryObject)
      for(let p=0;p < playerCount;p++){
        let playerAddress = await this.state.contracts['DAOG'].allPlayers(p).call()
        players[p] = await this.state.contracts['DAOG'].players(playerAddress).call()
        if(victoryObject && typeof victoryObject.inventory == "function"){
          players[p].victoryInventory = await victoryObject.inventory(this.state.contracts['DAOG']._address,playerAddress).call()
        }
      }
      this.setState({
        playerCount: playerCount,
        players:players,
        enoughPlayersReady: await this.state.contracts['DAOG'].enoughPlayersReady().call(),
      })
    }

  }
  async loadObjects(){
    let objectsCount = await this.state.contracts["DAOG"].getObjectCount().call()
    let objectsArray = []
    let objects = []
    console.log(" &&&&&&&&&&&&&&&&&&&& &&&&&&&&&&&&&&&&&&&& LOADING OBJECTS &&&&&&&&&&&&&&&&&&&& &&&&&&&&&&&&&&&&&&&& ")
    if(objectsCount!=this.state.objectsCount){
      //load all objects in one batch first...
      for(let o=0;o < objectsCount;o++){
        objectsArray[o] = await this.state.contracts["DAOG"].objects(o).call()

        if(objectsArray[o]!="0x0000000000000000000000000000000000000000"){
          let objectContract = await this.getContractByAddress(objectsArray[o])
          if(!objectContract||!objectContract.loadedEmoji){
            console.log("Cant find contract for ",objectsArray[o],"loading dynamically...")
            objectContract = await this.loadContractForGenericObject(objectsArray[o])
          }
          objects[o]=objectContract;
          //console.log("** ( * OBJECT: )",objectContract)
          if(!objects[o]){
            console.log("ERROR!!! CAN'T FIND CONTRACT FOR OBJECT ADDRESS ",objectsArray[o])
          }
        }
        //console.log("LOADOBJECTS",o,objectsArray[o],objects[o].loadedEmoji)
      }
      this.setState({
        objectsCount: objectsCount,
        objects:objects,
        objectsArray:objectsArray,
      },async ()=>{
        //console.log("UPDATED OBJECTS ("+this.state.oObjects+"):",this.state.objectsArray)
      })
    }
  }
  async loadCustomObject(customLoader,contracts,name){
    contracts[name] = customLoader(name)
    contracts[name].type = await contracts[name].objectType().call()
  }
  setAddingObjects(deployedContractAddress){
    if(this.state.selection){
      let currentArray = this.state.addingObjects
      if(!currentArray) currentArray = []
      if(!currentArray[this.state.selection]) currentArray[this.state.selection] = []
      currentArray[this.state.selection].push(deployedContractAddress)
      this.setState({addingObjects:currentArray})
    }else{
      let currentArray = this.state.addingObjects
      currentArray.push(deployedContractAddress)
      this.setState({addingObjects:currentArray})
    }
  }
  clearAddingObjects(){
    this.setState({addingObjects:[]})
  }
  openScanner(){
    this.setState({scanner:true})
  }
  render() {
    let {web3,account,contracts,tx,gwei,block,avgBlockTime,etherscan} = this.state
    let connectedDisplay = []
    let contractsDisplay = []
    let qrSize = Math.min(document.documentElement.clientWidth,512)-90

    //console.log(document.documentElement.clientWidth,document.documentElement.clientHeight)
    let result = Math.min(1400,document.documentElement.clientWidth)
    //console.log("result",result)
    TOP_PADDING = Math.round((result/1400)*190);//1.2*/7/*
    HEIGHT_RATIO = Math.round(result/1400*100)


    let scanButtonStyle = {
      opacity:0.8,
      position:"fixed",
      right:20,
      bottom:20,
      zIndex:2,
      cursor:"pointer"
    }

    let scanButton = (
      <div style={scanButtonStyle} onClick={() => {
          this.openScanner()
        }} >
        <div style={{position:'relative',backgroundImage:"linear-gradient(#cccccc,#dddddd)",backgroundColor:"#eeeeee",borderRadius:"50%",width:125,height:125,boxShadow: "0.5px 0.5px 5px #000000"}}>
          <a href="#" style={{color:'#FFFFFF',position:'absolute',left:20,top:20}}>
            <img src={scan} style={{maxWidth:87,opacity:0.777}} />
          </a>
        </div>
      </div>
    )

    let walletButtonStyle = {
      opacity:0.8,
      position:"fixed",
      right:-2,
      top:-2,
      zIndex:99,
      cursor:"pointer"
    }

    let url = "https://wallet.daog.io"
    if(this.state.metaAccount){
      url+="/pk#"+this.state.metaAccount.privateKey
    }

    let walletIframe = ""
    if(this.state && this.state.contracts && this.state.contracts["DAOG"]){
      if(this.state.walletIframe)
      {
        walletIframe=(
          <iframe allow={"microphone;camera"} style={{width:"85%",height:"85%",position:"fixed",top:"6%",right:"6%",zIndex:9999}} src={url} />
        )
      }else{
        walletIframe=(
          <iframe allow={"microphone;camera"} style={{width:0,height:0,position:"absolute",top:"-9000",left:"-9000",zIndex:-9999}} src={url} />
        )
      }
    }

    let walletButton = (
      <div style={walletButtonStyle}>
        <div style={{position:'relative',backgroundImage:"linear-gradient(#cccccc,#dddddd)",backgroundColor:"#eeeeee",borderRadius:"50%",width:35,height:35,boxShadow: "0.3px 0.3px 3px #000000"}}
          onClick={()=>{
            this.setState({walletIframe:!this.state.walletIframe})
          }}
          >
          <img src={wallet} style={{maxWidth:27,opacity:0.777,margin:3}} />
        </div>
      </div>
    )
    /*
    if(this.state.showingPk){
    let url = "https://daog.io/pk#"+this.state.metaAccount.privateKey.replace("0x","")
    return (
    <div style={{backgroundColor:"#ffffff",width:"100%",height:"100%"}}>
    <div style={{padding:"2%",paddingBottom:"5%",backgroundColor:"#FFFFFF",textAlign:"center"}}>

    <div style={{fontSize:"1vw",color:"#000000",padding:"1%"}}>
    Your private key and with url to DAOG.io:<div> (scan with your phone's camera)</div>
    </div>

    <QRCode value={url} size={qrSize}/>
    <div style={{fontSize:"1vw",color:"#000000",padding:"1%"}}>
    {url}
    </div>
    <div>
    {this.state.metaAccount.address}
    </div>

    <div style={{textAlign:'center',padding:"5%",paddingTop:"15%"}}>
    <Button size="3" color={"blue"} onClick={async ()=>{
    window.location = "/"
    }}>
    Done
    </Button>
    </div>
    </div>
    </div>
    )
    }*/


    if(web3){


      /*connectedDisplay.push(
      <Gas
      key="Gas"
      onUpdate={(state)=>{
      console.log("Gas price update:",state)
      this.setState(state,()=>{
      console.log("GWEI set:",this.state)
      })
      }}
      />
      )*/
      if(this.state.contracts && this.state.block && this.state.contracts['History'] && this.state.account){
        connectedDisplay.push(
          <div>
            <Events
              config={{hide:true}}
              contract={this.state.contracts["History"]}
              eventName={"AddMechanic"}
              block={this.state.block}
              filter={{player:this.state.account}}
              onUpdate={(eventData,allEvents)=>{
                let currentMechanics = this.state.myMechanics
                if(currentMechanics.indexOf(eventData.mechanic)>=0){
                  //console.log(eventData.mechanic+" already exists, skip")
                }else{
                  currentMechanics.push(eventData.mechanic)
                  this.setState({myMechanics:currentMechanics},()=>{
                   // console.log("myMechanics SET:",this.state.myMechanics)
                  })
                }

              }}
              />
          </div>
        )
      }
      /*
      <Events
      config={{hide:true}}
      contract={this.state.contracts["History"]}
      eventName={"AddMechanic"}
      block={this.state.block}
      filter={{daog:this.state.contracts['DAOG']._address}}
      onUpdate={(eventData,allEvents)=>{
      console.log("daogMechanics",allEvents)
      this.setState({daogMechanics:allEvents})
      }}
      />
      <Events
      config={{hide:true}}
      contract={this.state.contracts["History"]}
      eventName={"AddMechanic"}
      block={this.state.block}
      filter={{game:this.state.contracts['Game']._address}}
      onUpdate={(eventData,allEvents)=>{
      console.log("gameMechanics",allEvents)
      this.setState({gameMechanics:allEvents})
      }}*/



      connectedDisplay.push(
        <ContractLoader
          key="ContractLoader"
          config={{DEBUG:true}}
          web3={web3}
          require={path => {return require(`${__dirname}/${path}`)}}
          onReady={async (contracts,customLoader)=>{
            /*await this.loadCustomObject(customLoader,contracts,"Population")
            await this.loadCustomObject(customLoader,contracts,"Mountain")
            //await this.loadCustomObject(customLoader,contracts,"BrickMountain")
            await this.loadCustomObject(customLoader,contracts,"Bricks")
            await this.loadCustomObject(customLoader,contracts,"Tree")
            await this.loadCustomObject(customLoader,contracts,"House")
            await this.loadCustomObject(customLoader,contracts,"Tent")
            //await this.loadCustomObject(customLoader,contracts,"Seedling")
            //await this.loadCustomObject(customLoader,contracts,"Tulip")*/
            if(this.state.DAOG){
              let possibleDAOG = customLoader("DAOG",this.state.DAOG)
              console.log("possibleDAOG",possibleDAOG)
              if(possibleDAOG && typeof possibleDAOG.molochContractAddress == "function"){
                let molochContractAddress = await possibleDAOG.molochContractAddress().call()
                console.log("molochContractAddress",molochContractAddress)
                let gameContractAddress = await possibleDAOG.gameContractAddress().call()
                console.log("gameContractAddress",gameContractAddress)
                contracts['Moloch'] = customLoader("Moloch",molochContractAddress)
                contracts['Game'] = customLoader("Game",gameContractAddress)
                contracts['DAOG'] = possibleDAOG
                this.setState({name:this.state.web3.utils.hexToUtf8(await possibleDAOG.name().call())})
              }else{
                console.log("NOT A FUNCTION")
              }
            }
            console.log("contracts loaded",contracts)
            this.setState({contracts:contracts,contractLoader:customLoader},async ()=>{
              //trigger poll
              setTimeout(this.poll.bind(this),100)
              console.log("Contracts Are Ready:",this.state.contracts)
              let victoryObject
              let victoryObjectType=0
              let victoryEmoji = "0x0000000000000000"
              let victoryObjectAddress = await this.state.contracts["DAOG"].victoryObjectAddress().call()
              if(victoryObjectAddress!="0x0000000000000000000000000000000000000000"){
                victoryObject  = this.state.contractLoader("Object",victoryObjectAddress)
                victoryObjectType = await victoryObject.objectType().call()
                console.log("victoryObject",victoryObject)
                victoryEmoji = await victoryObject.emoji().call()
                console.log("victoryEmoji",victoryEmoji)
              }
              let victoryEmojiDisplay = ""
              if(victoryEmoji){
                victoryEmojiDisplay = this.state.web3.utils.hexToUtf8(victoryEmoji)
              }
              let gameContract

              let tileColors = []
              let tileColorsLength = await this.state.contracts["Game"].tileColorsLength().call()
              for(let t=0;t<tileColorsLength;t++){
                tileColors[t] = this.state.web3.utils.numberToHex(await this.state.contracts["Game"].tileColors(t).call())
              }

              let utilityEmoji = (await this.state.contracts["Game"].utilityEmoji().call())
              if(utilityEmoji){
                utilityEmoji = this.state.web3.utils.hexToUtf8(utilityEmoji)
              }

              let gameName = await this.state.contracts["Game"].name().call()
              if(gameName){
                gameName = this.state.web3.utils.hexToUtf8(gameName)
              }

              this.setState({
                tileColors: tileColors,
                buyIn: await this.state.contracts["DAOG"].buyIn().call(),
                hexagonal: await this.state.contracts["DAOG"].hexagonal().call(),
                backgroundColor: await this.state.contracts["Game"].backgroundColor().call(),
                gameName: gameName,
                startingInfluence: parseInt(await this.state.contracts["DAOG"].startingInfluence().call()),
                periodDuration: parseInt(await this.state.contracts["Moloch"].periodDuration().call()),
                votingPeriodLength: parseInt(await this.state.contracts["Moloch"].votingPeriodLength().call()),
                gracePeriodLength: parseInt(await this.state.contracts["Moloch"].gracePeriodLength().call()),
                abortWindow: parseInt(await this.state.contracts["Moloch"].abortWindow().call()),
                costToSubmitSubmitProposal: parseInt(await this.state.contracts["Game"].costToSubmitSubmitProposal().call()),
                costToVote: parseInt(await this.state.contracts["Game"].costToVote().call()),
                utilityEmoji: utilityEmoji,
                victoryObjectAddress: victoryObjectAddress,
                costToExplore: parseInt((await this.state.contracts["Game"].costToExplore().call())),
                startingBalance: parseInt((await this.state.contracts["Game"].startingBalance().call())),
                victoryAmount: await this.state.contracts["DAOG"].victoryAmount().call(),
                victoryObject: victoryObject,
                victoryObjectType: victoryObjectType,
                victoryObjectEmoji: victoryEmojiDisplay,
              },()=>{
                console.log(this.state);
                this.loadObjects()
                this.setLoader(false)
              })
            })
          }}
          />
      )
      connectedDisplay.push(
        <Transactions
          key="Transactions"
          config={{DEBUG:false,hide:true/*!this.state.debug*/}}
          account={account}
          gwei={gwei}
          web3={web3}
          block={block}
          avgBlockTime={avgBlockTime}
          etherscan={etherscan}
          metaAccount={this.state.metaAccount}
          onReady={(state)=>{
            console.log("Transactions component is ready:",state)
            this.setState(state)
          }}
          onReceipt={(transaction,receipt)=>{
            // this is one way to get the deployed contract address, but instead I'll switch
            //  to a more straight forward callback system above
            console.log("Transaction Receipt",transaction,receipt)
          }}
          />
      )

      if(contracts&&this.state.backgroundColor){
        if(this.state&&!this.state.DAOG){
          contractsDisplay.push(
            <div>
              <FrontPage
                {...this.state}
                setContractMenu={this.setContractMenu.bind(this)}
                closeContractMenu={this.closeContractMenu.bind(this)}
                setLoader={this.setLoader.bind(this)}
                setLoaderByTransaction={this.setLoaderByTransaction.bind(this)}
                loadContractForGenericObject={this.loadContractForGenericObject.bind(this)}
                setMenu={this.setMenu.bind(this)}
                addMyMechanics={this.addMyMechanics.bind(this)}
                scanButton={scanButton}
                />
            </div>
          )
        }else if(this.state.phase==1){
          contractsDisplay.push(
            <Lobby key="lobby" {...this.state}
              clearAddingObjects={this.clearAddingObjects.bind(this)}
              topPadding={TOP_PADDING}
              setContractMenu={this.setContractMenu.bind(this)}
              closeContractMenu={this.closeContractMenu.bind(this)}
              openScanner={this.openScanner.bind(this)}
              scanButton={scanButton}
              setLoader={this.setLoader.bind(this)}
              setLoaderByTransaction={this.setLoaderByTransaction.bind(this)}
              />
          )
        }else if(this.state.phase==2 && this.state.size>1){
          contractsDisplay.push(
            <Game
              key="game" {...this.state}
              setMenu={this.setMenu.bind(this)}
              topPadding={TOP_PADDING}
              heightRatio={HEIGHT_RATIO}
              getContractByAddress={this.getContractByAddress.bind(this)}
              loadObjects={this.loadObjects.bind(this)}
              loadContractForGenericObject={this.loadContractForGenericObject.bind(this)}
              closeMenu={this.closeMenu.bind(this)}
              clearAddingObjects={this.clearAddingObjects.bind(this)}
              setContractMenu={this.setContractMenu.bind(this)}
              closeContractMenu={this.closeContractMenu.bind(this)}
              addMyMechanics={this.addMyMechanics.bind(this)}
              setLoader={this.setLoader.bind(this)}
              setLoaderByTransaction={this.setLoaderByTransaction.bind(this)}
              />
          )
        }else if(this.state.phase==3||this.state.phase==4){
          contractsDisplay.push(
            <Recap key="recap" {...this.state} topPadding={TOP_PADDING} setLoader={this.setLoader.bind(this)} setLoaderByTransaction={this.setLoaderByTransaction.bind(this)}/>
          )
        }
      }
    }else{
      connectedDisplay = (
        <div style={{width:"100%",height:"100%",fontSize:"25vw",opacity:0.15,textAlign:"center",padding:"5%"}}>
          🐶
        </div>
      )
    }


    let menu = ""
    if(this.state.menu){
      if(this.state.menuMode=="map"){
      }else{
        menu = (
          <MenuObjects {...this.props} {...this.state} topPadding={TOP_PADDING}
            setAddingObjects={this.setAddingObjects.bind(this)}
            addMyMechanics={this.addMyMechanics.bind(this)}
            loadContractForGenericObject={this.loadContractForGenericObject.bind(this)}
            setContractMenu={this.setContractMenu.bind(this)}
            closeContractMenu={this.closeContractMenu.bind(this)}
            closeMenu={this.closeMenu.bind(this)}
            setLoader={this.setLoader.bind(this)}
            setLoaderByTransaction={this.setLoaderByTransaction.bind(this)}
            />
        )
      }

    }

    let contractMenu = ""
    if(this.state.contractMenu){
      contractMenu = (
        <MenuContract
          {...this.props} {...this.state} topPadding={TOP_PADDING}
          setAddingObjects={this.setAddingObjects.bind(this)}
          addMyMechanics={this.addMyMechanics.bind(this)}
          loadContractForGenericObject={this.loadContractForGenericObject.bind(this)}
          setContractMenu={this.setContractMenu.bind(this)}
          closeContractMenu={this.closeContractMenu.bind(this)}
          closeMenu={this.closeMenu.bind(this)}
          setLoader={this.setLoader.bind(this)}
          setLoaderByTransaction={this.setLoaderByTransaction.bind(this)}
          />
      )
    }

    //console.log("RENDER WITH LOADER: ",this.state.loader)
    let loader = (
      <FadeDownBox pose={this.state.loader ? 'visible' : 'hidden'}>
        <Loader {...this.state}/>
      </FadeDownBox>
    )


    if(this.state.scanner){
      return (
        <Scanner {...this.state} onClose={()=>{this.setState({scanner:false})}} goBack={()=>{this.setState({scanner:false})}}/>
      )
    }



    if(this.state.tokens<=0 || this.state.balance<=0){
      return (
        <div style={{width:"100%",height:"100%",backgroundColor:"#FFFFFF"}}>
          <div style={{fontSize:"16vw",padding:"10%",textAlign:"center"}}>
            🐶 DAOG
          </div>
          <div style={{fontSize:"6vw",padding:"10%",textAlign:"center"}}>
            - private alpha testing -
          </div>
          <div style={{fontSize:"6vw",padding:"10%",textAlign:"center"}}>
            🐶{this.state.tokens} & ${this.state.balance}
          </div>
          <div style={{fontSize:"4vw",padding:"10%",textAlign:"center"}}>
            Send 🐶DAOG & xDai here to play:
          </div>
          <div style={{padding:"2%",paddingBottom:"5%",backgroundColor:"#FFFFFF",textAlign:"center"}}>
            <QRCode value={this.state.account} size={qrSize}/>
            <div style={{fontSize:"3vw"}}>
              {this.state.account}
            </div>
          </div>
          <div style={{padding:"25%",paddingBottom:"25%",backgroundColor:"#FFFFFF",textAlign:"center"}}>

          </div>

          {scanButton}
          {walletButton}
          {walletIframe}
        </div>
      )
    }

    //DEBUG MODE BUTTON
    let bottomRight = (
      <div style={{cursor:"pointer",position:'fixed',right:0,bottom:0}} onClick={()=>{
          this.setState({debug:!this.state.debug},()=>{localStorage.setItem("debug",this.state.debug?"yes":"no")})
        }}>
        🐞
      </div>
    )
    if(window.location.href.indexOf("localhost")>=0){
      //allow bug button in localhost
    }else{
      //override with chat icon to signal there will be chat
      bottomRight = (
        <div style={{cursor:"pointer",position:'fixed',right:0,bottom:0,fontSize:"2vw",padding:"1%"}} onClick={()=>{

          }}>
          💬
        </div>
      )
    }

    return (
      <div className="App">
        <PrivateKeyCatcher newPrivateKey={(pk)=>{
          this.setState({newPrivateKey:pk})
        }}/>
        <Dapparatus
          config={{
            DEBUG:false,
            adjustedZoom:1,
            metatxAccountGenerator:false,
            requiredNetwork:['Unknown','Private','xDai'],
            hide:false,
            outerBoxStyle:{
              position:'fixed',right:"7%",top:0,zIndex:21
            }
          }}
          metatx={false}
          newPrivateKey={this.state.newPrivateKey}
          fallbackWeb3Provider={new Web3.providers.HttpProvider(PROVIDER)}
          onUpdate={(state)=>{
            console.log("metamask state update:",state)
            if(state.web3Provider) {
              state.web3 = new Web3(state.web3Provider)
              this.setState(state)
            }
          }}
          />
          {connectedDisplay}
          {contractsDisplay}
          {bottomRight}
          {menu}
          {contractMenu}
          {loader}
          {walletButton}
          {walletIframe}
        </div>
      );
    }
  }

  export default App;
