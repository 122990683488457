import React from 'react';
import Helpers from "../helpers.js"
import Badge from './Badge'
import { Scaler, Button  } from "dapparatus"


export default class Header extends React.Component {
  getObjectsOfType(objectType){
      if(!this.props.objects){
        return "..."
      }else{

        if(typeof objectType == "string"){
          objectType = objectType.split("|")
        }

        return (
            this.props.objects.map((object)=>{
              //console.log("OOOOBJ",object)
              for(let thisType in objectType){
                if(object.type == ""+Helpers.objectTypeToIndex[objectType[thisType]]){
                  return (
                   <span style={{cursor:"pointer",zIndex:22}} onClick={()=>{
                     this.props.setContractMenu(object,"")
                   }}>
                    {object.loadedEmoji}
                    </span>
                  )
                }
              }

           })
         )
      }

  }
  render() {
    let inventory = []
    for(let i in this.props.inventory){
      //console.log("this.props.inventory",i,this.props.inventory[i])
      if(this.props.inventory[i]>0){
        inventory.push(
          <Badge key={"inv"+i} outer={i} inner={this.props.inventory[i]}
            size={8} color="#77715a" hightlight="#ddd19f"
          />
        )
      }
    }

    let victoryButton = ""
    if(!this.props.winner && Helpers.objectType[this.props.victoryObjectType] == "Inventory" && parseInt(this.props.inventory[this.props.victoryObjectEmoji]) >= parseInt(this.props.victoryAmount)){
      victoryButton = (
        <div style={{position:"fixed",left:"20%",top:"20%",zIndex:22}}>
          <Button key="finish" color={"green"} size={"3"} onClick={async ()=>{
              this.props.setLoader(true,5,"🏁 Finishing...")
              //submitVote(uint256 proposalIndex, uint8 uintVote)
              this.props.tx(
                this.props.contracts["DAOG"].finish(0,0),
                2000000,(result,err)=>{
                  this.props.setLoaderByTransaction(result,err,err?4500:1500)
                  console.log("CALLBACK",result)
                  this.props.closeMenu()
                }
              )
            }}>
            Claim Victory!
          </Button>
        </div>
      )
    }

    let victoryBadge = ""
    if(this.props.victoryObject){
      victoryBadge = (
        <Badge outer={this.props.victoryObjectEmoji} inner={this.props.victoryAmount}
          size={10} color={Helpers.objectColor[this.props.victoryObjectType][0]} hightlight="#DDDDDD"
        />
      )
    }


    return (
      <div>

      <div style={{position:"fixed",left:10,top:this.props.topPadding+10,opacity:0.333,fontSize:"6vw",letterSpacing:-1}}>
        {this.props.name}
      </div>

      <div key="header" style={{zIndex:22,paddingTop:5,position:'fixed',left:0,top:0,width:"100%",height:this.props.topPadding-5,backgroundColor:"#1F1F1F"}}>

        <Scaler config={{startZoomAt:1600,origin:"5px 5px",adjustedZoom:1.3}}>
          <div style={{padding:2,width:"150%"}}>

            <Badge link={"/"} outer={"🐶"} inner={"$"+(Math.round(this.props.tokens/10**15)/1000).toFixed(2)} onClick={()=>{alert("CLICK");window.location = "/"}}
              size={10} color="#505050" hightlight="#dddddd"
            />

            {victoryBadge}

            {inventory}
          </div>
          <div style={{padding:25,width:"100%",zIndex:22}}>
            <Badge outer={"🗃️"} inner={this.getObjectsOfType("Inventory")}
              size={10} color={Helpers.objectColor[1][0]} hightlight={Helpers.objectColor[1][1]}
            />
            <Badge outer={"🔨"} inner={this.getObjectsOfType("Buildable|ProximityBuildable")}
              size={10} color={Helpers.objectColor[2][0]} hightlight={Helpers.objectColor[2][1]}
            />
            <Badge outer={"🌱"} inner={this.getObjectsOfType("Growable")}
              size={10} color={Helpers.objectColor[4][0]} hightlight={Helpers.objectColor[4][1]}
            />
            <Badge outer={"🔭"} inner={this.getObjectsOfType("Discoverable")}
              size={10} color={Helpers.objectColor[3][0]} hightlight={Helpers.objectColor[3][1]}
            />
            <Badge outer={"💵"} inner={"$"+(Math.round(this.props.pot/10**15)/1000).toFixed(2)}
              size={10} color="#444444" hightlight="#bbbbbb"
            />
          </div>
        </Scaler>
      </div>

      {victoryButton}
      </div>
    )
  }
}
/*
<div key="headerunder" style={{zIndex:10,paddingTop:5,width:"100%",height:this.props.topPadding-5}}>
  ... yo ...
</div>
 */
