import React from 'react';
import { Button, Blockie } from "dapparatus"
import Helpers from "../helpers.js"



export default class MenuObjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    this.init()
  }
  async init(){
    console.log("LOADING CONTRACT",this.props.selectedContract)
    let costs = []
    if(typeof this.props.selectedContract.costsLength =="function"){
      for(let c=0;c<await this.props.selectedContract.costsLength().call();c++){
        costs[c] = await this.props.selectedContract.costs(c).call()
      }
    }

    let earnings = []
    if(typeof this.props.selectedContract.earningsLength =="function"){
      for(let e=0;e<await this.props.selectedContract.earningsLength().call();e++){
        earnings[e] = await this.props.selectedContract.earnings(e).call()
      }
    }


    let probability = false
    if(typeof this.props.selectedContract.probability == "function"){
      probability = await this.props.selectedContract.probability().call()
    }


    let plantedOn = false
    if(typeof this.props.selectedContract.plantedOn == "function"){
      plantedOn = await this.props.selectedContract.plantedOn().call()
    }
    let blocksToGrow = false
    if(typeof this.props.selectedContract.blocksToGrow == "function"){
      blocksToGrow = await this.props.selectedContract.blocksToGrow().call()
    }
    let growToEmoji = false
    if(typeof this.props.selectedContract.growToEmoji == "function"){
      growToEmoji = await this.props.selectedContract.growToEmoji().call()
    }


    let builtOn = false
    if(typeof this.props.selectedContract.builtOn == "function"){
      builtOn = await this.props.selectedContract.builtOn().call()
    }
    let builtNear = false
    if(typeof this.props.selectedContract.builtNear == "function"){
      builtNear = await this.props.selectedContract.builtNear().call()
    }
    let influence = false
    if(typeof this.props.selectedContract.influence == "function"){
      influence = await this.props.selectedContract.influence().call()
    }

    this.setState({costs,earnings,builtOn,builtNear,influence,plantedOn,blocksToGrow,growToEmoji,probability},()=>{console.log(this.state)})
  }

  render() {
    let {} = this.state

    let top = 0
    if(this.state.phase>1){
      top = this.props.topPadding
    }

    let displayForm = []

    if(this.props.selectedContract.typeName == 'Discoverable'){
      displayForm.push(
        <div style={{fontSize:"3vw",padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
          probability:
          <input
            style={{verticalAlign:"middle",fontSize:38,width:150,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="probability" value={this.state.probability}
          /> ({Math.round(this.state.probability*100/65535)}%)
        </div>
      )
    }


    if(this.props.selectedContract.typeName == 'Growable'){
      displayForm.push(
        <div style={{fontSize:"3vw",padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
          planted on:
          <input
            style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="plantedOn" value={Helpers.toEmoji(this.props.web3,this.state.plantedOn)}
          /> blocks:
          <input
            style={{verticalAlign:"middle",fontSize:38,width:80,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="blocksToGrow" value={this.state.blocksToGrow}
          /> grow emoji:
          <input
            style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="growToEmoji" value={Helpers.toEmoji(this.props.web3,this.state.growToEmoji)}
          />
        </div>
      )
    }



    if(this.props.selectedContract.typeName == 'Buildable'){
      displayForm.push(
        <div style={{fontSize:"4vw",padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
          built on: <input
            style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="builtOn" value={Helpers.toEmoji(this.props.web3,this.state.builtOn)}
          /> influence:
          <input
            style={{verticalAlign:"middle",fontSize:38,width:80,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="influence" value={this.state.influence}
          />
        </div>
      )
    }
    if(this.props.selectedContract.typeName == 'ProximityBuildable'){
      displayForm.push(
        <div style={{fontSize:"4vw",padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
          built on: <input
            style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="builtOn" value={Helpers.toEmoji(this.props.web3,this.state.builtOn)}
          />
          near: <input
            style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="builtNear" value={Helpers.toEmoji(this.props.web3,this.state.builtNear)}
          />
          influence:
          <input
            style={{verticalAlign:"middle",fontSize:38,width:80,textAlign:"center",backgroundColor:"#dddddd"}}
            type="text" name="influence" value={this.state.influence}
          />
        </div>
      )
    }


    if(this.state.costs){
      for(let c in this.state.costs){
        displayForm.push(
          <div style={{fontSize:"4vw",padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
            <div>COSTS</div>
            emoji:
            <input
              style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
              type="text" name="costEmoji" value={Helpers.toEmoji(this.props.web3,this.state.costs[c].emoji)}
            />
            cost:
            <input
              style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
              type="text" name="costAmount" value={this.state.costs[c].amount}
            />
          </div>
        )
      }
    }


    if(this.state.earnings){
      for(let e in this.state.earnings){
        displayForm.push(
          <div style={{fontSize:"4vw",padding:20,margin:10,color:"#666666",border:"1px solid #dddddd"}}>
            <div>EARNINGS</div>
            emoji:
            <input
              style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
              type="text" name="earnEmoji" value={Helpers.toEmoji(this.props.web3,this.state.earnings[e].emoji)}
            /> amount:
            <input
              style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
              type="text" name="earnAmount" value={this.state.earnings[e].amount}
            /> blocks:
            <input
              style={{verticalAlign:"middle",fontSize:38,width:50,textAlign:"center",backgroundColor:"#dddddd"}}
              type="text" name="earnBlock" value={this.state.earnings[e].blocks}
            />
          </div>
        )
      }
    }


    let buttons = []

    if(this.props.contractMenuReturns=="setAddingObjects"){
      buttons.push(
        <Button size="3" color={"orange"} onClick={async ()=>{
          this.props.closeContractMenu()
        }}>
          Cancel
        </Button>
      )
      buttons.push(
        <Button size="3" color={"green"} onClick={async ()=>{
          let object ={
            address: this.props.selectedContract._address,
            emoji: this.props.selectedContract.loadedEmoji
          }
          this.props.setAddingObjects(object)
          this.props.closeContractMenu()
          this.props.closeMenu()
        }}>
          Add Mechanic
        </Button>
      )
    }else{
      buttons.push(
        <Button size="3" color={"blue"} onClick={async ()=>{
          this.props.closeContractMenu()
        }}>
          Done
        </Button>
      )
    }

    return (
      <div key="contract_menu" style={{zIndex:32,overflow:"scroll",position:'fixed',opacity:0.97,left:0,top:top,width:"100%",height:"100%",backgroundColor:"#777777"}}>
        <div style={{zIndex:31,cursor:"pointer",fontSize:"6vw",position:'absolute',right:"2%",top:"6%"}} onClick={this.props.closeContractMenu}>
          ❌
        </div>
        <div style={{padding:"5%"}}>
          <div style={{fontSize:"6vw",width:"80%",padding:10,backgroundColor:"#444444".replace("0x","#"),border:"1px solid #666666"}}>
            <span style={{float:'right',fontSize:"2vw",color:"#DDDDDD",opacity:0.5}}>{this.props.selectedContract._address}</span> {this.props.selectedContract.loadedEmoji}
          </div>

          <div style={{fontSize:"6vw",width:"80%",padding:10,backgroundColor:"#444444".replace("0x","#"),border:"1px solid #666666"}}>
            {this.props.selectedContract.typeName}
          </div>

          <div style={{fontSize:"6vw",width:"80%",padding:10,backgroundColor:"#333333".replace("0x","#"),border:"1px solid #666666"}}>
            {displayForm}
          </div>


          <div>
            {buttons}
          </div>
        </div>
      </div>
    )
  }
}
