import React from 'react';
import { Button, Blockie, Scaler } from "dapparatus"
import Helpers from "../helpers.js"
import Badge from './Badge'

export default class MenuMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tile:{}
    }
  }
  componentDidMount(){
    this.init()
  }
  async init(){
    console.log("menu for ",this.props.selection,this.props.contracts)
    if(this.props.land){
      let thisTile = this.props.land[this.props.selection[0]][this.props.selection[1]]
      let address = await this.props.contracts["DAOG"].getAddress(thisTile.emojiBytes).call()

      let contract
      if(address&&address!="0x0000000000000000000000000000000000000000"){
        contract = await this.props.loadContractForGenericObject(address)
      }

      let thisX = this.props.selection[0]
      let thisY = this.props.selection[1]

      let nearByEmojis = []
      //look through game mechanics for any proximity buildables to look for legal builds

      let neighbors = Helpers.neighbors(thisX,thisY,this.props.size,this.props.hexagonal)
      //console.log(neighbors)
      for(let n in neighbors){
        let neighborTile = this.props.land[neighbors[n][0]][neighbors[n][1]]
        //console.log("neighborTile",neighborTile)
        if(neighborTile&&neighborTile.emoji&&neighborTile.owner==this.props.account){
          nearByEmojis.push({emoji:neighborTile.emoji,x:neighbors[n][0],y:neighbors[n][1]})
        }
      }

      //console.log("nearByEmojis",nearByEmojis)

      this.setState({
        x:thisX,
        y:thisY,
        tile:thisTile,
        contract: contract,
        nearByEmojis: nearByEmojis
      },()=>{
        console.log("INIT DONE:",this.state)

      })
    }else{
      console.log("no land")
    }
  }

  render() {
    let {tile} = this.state

    let buttons = []

    //console.log("this.state.contract",this.state.contract)

    if(this.state.contract && typeof this.state.contract.onCollect == "function"){
      for(let e in this.state.tile.earnings){
        let earning = this.state.tile.earnings[e]
        if(earning.emoji){
          let foundCollect = false
          let color
          let emoji
          let wording
          if(earning.toGo=="-1"){
            emoji = "🔼"
            color = "blue"
            wording = "Upgrade"
          }else if(earning.toGo>0){
            emoji = Helpers.blockClock(tile.earnings[e].toGo)
            color = "yellow"
            wording = ""
          }else{
            emoji = earning.emoji
            color = "green"
            wording = "Collect"
            foundCollect = true
          }
          buttons.push(
            <Scaler config={{startZoomAt:800,origin:"0% 50%",adjustedZoom:1}}>
              <Button key={"collect"+emoji} color={color} size={"3"} onClick={async ()=>{
                  this.props.setLoader(true,5,emoji+" "+wording+"...")
                  //submitVote(uint256 proposalIndex, uint8 uintVote)
                  this.props.tx(this.props.contracts["DAOG"].collect(this.state.x,this.state.y),1000000,(receipt,err)=>{
                    this.props.setLoaderByTransaction(receipt,err,err?4500:1500)
                    console.log(receipt)
                  })
                  this.props.closeMenu()
                }}>
                {wording} {emoji}
              </Button>
            </Scaler>
          )
          if(foundCollect) break
        }
      }
    }

    if(this.state.contract && this.state.contract.loadedGrowToEmoji && this.state.tile && this.state.tile.blocksGrown>=this.state.tile.blocksToGrow && this.state.tile.blocksToGrow!=0){
        buttons.push(
          <Scaler config={{startZoomAt:800,origin:"0% 50%",adjustedZoom:1}}>
            <Button key={"harvest_"+this.state.contract.growToEmoji} color={this.state.contract.hasGrownEnough?"green":"yellow"} size={"3"} onClick={async ()=>{
                this.props.setLoader(true,5,this.state.contract.loadedGrowToEmoji+" Growing...")
                //submitVote(uint256 proposalIndex, uint8 uintVote)
                this.props.tx(
                  this.props.contracts["DAOG"].plant(this.state.x,this.state.y,this.props.web3.utils.toHex(this.state.contract.loadedEmoji)),
                  2000000,(result,err)=>{
                    console.log("CALLBACK",result)
                    this.props.setLoaderByTransaction(result,err,err?4500:1500)
                  }
                )
                this.props.closeMenu()
              }}>
              Grow To {this.state.contract.loadedGrowToEmoji}
            </Button>
          </Scaler>
        )

    }else if(this.state.contract && this.state.contract.loadedGrowToEmoji && this.state.tile && this.state.tile.blocksToGrow==0){
        buttons.push(
         <Scaler config={{startZoomAt:800,origin:"0% 50%",adjustedZoom:1}}>
            <Button key={"harvest_"+this.state.contract.growToEmoji} color={this.state.contract.hasGrownEnough?"green":"yellow"} size={"3"} onClick={async ()=>{
                this.props.setLoader(true,5,this.state.contract.loadedGrowToEmoji+" Sowing...")
                //submitVote(uint256 proposalIndex, uint8 uintVote)
                this.props.tx(
                  this.props.contracts["DAOG"].plant(this.state.x,this.state.y,this.props.web3.utils.toHex(this.state.contract.loadedEmoji)),
                  2000000,(result,err)=>{
                    console.log("CALLBACK",result)
                    this.props.setLoaderByTransaction(result,err,err?4500:1500)
                  }
                )
                this.props.closeMenu()
              }}>
              Sow {this.state.contract.loadedEmoji}
            </Button>
          </Scaler>
        )

    }

    if(this.props.objects){
      let theEmojiHere = ""
      if(this.state.contract){
        theEmojiHere = this.state.contract.loadedEmoji
      }
      // console.log("SEARCHING OBJECTS TO SEE IF ANYTHING CAN BE PLANTED ON ",theEmojiHere,"all objects",this.props.objects)
      for(let o in this.props.objects){
        //console.log(this.props.objects[o].loadedEmoji)
        if(typeof this.props.objects[o].plantedOn == "function"){
          if(this.props.objects[o].plantedOnEmoji == theEmojiHere){
            let costs = []
            for(let c in this.props.objects[o].loadedCosts){
              //console.log("adding ",this.props.objects[o].loadedCosts[c])
              costs.push(
                <Badge key={"badge_"+c+"_plant_"+o+"_"+theEmojiHere} outer={this.props.objects[o].loadedCosts[c].emoji} inner={this.props.objects[o].loadedCosts[c].amount}
                  size={8} color={Helpers.objectColor[1][0]} hightlight={Helpers.objectColor[1][1]}
                />
              )
            }
            buttons.push(
              <Scaler config={{startZoomAt:800,origin:"0% 50%",adjustedZoom:1}}>
                <Button key={"plant_"+o+"_"+theEmojiHere} color={"blue"} size={"3"} onClick={async ()=>{

                    for(let c in this.props.objects[o].loadedCosts){
                      console.log("INVENTORY",this.props.inventory[this.props.objects[o].loadedCosts[c].emoji],"NEEDED:",this.props.objects[o].loadedCosts[c].amount)
                      if(!this.props.inventory || !this.props.inventory[this.props.objects[o].loadedCosts[c].emoji] || parseInt(this.props.inventory[this.props.objects[o].loadedCosts[c].emoji]) < parseInt(this.props.objects[o].loadedCosts[c].amount)){
                        this.props.setLoaderByTransaction({},"Not enough "+this.props.objects[o].loadedCosts[c].emoji,5000)
                        return;
                      }
                    }

                    this.props.setLoader(true,5,this.props.objects[o].loadedEmoji+" Planting...")

                    this.props.tx(
                      this.props.contracts["DAOG"].plant(this.state.x,this.state.y,this.props.web3.utils.toHex(this.props.objects[o].loadedEmoji)),
                      2000000,(result,err)=>{
                        console.log("CALLBACK",result)
                        this.props.setLoaderByTransaction(result,err,err?4500:1500)
                      }
                    )
                    this.props.closeMenu()
                  }}>
                    {costs} Plant {this.props.objects[o].loadedEmoji}
                </Button>
              </Scaler>
            )
          }
        }

        console.log("SEARCHING OBJECTS TO SEE IF ANYTHING CAN BE BUILT ON ",theEmojiHere,"this object",this.props.objects[o].loadedEmoji)
        if(typeof this.props.objects[o].builtOn == "function"){
          console.log("COMPARE ",this.props.objects[o].builtOnEmoji,theEmojiHere,this.props.web3.utils.toHex(this.props.objects[o].builtOnEmoji),this.props.web3.utils.toHex(theEmojiHere))
          if(this.props.objects[o].builtOnEmoji == theEmojiHere){
            let costs = []
            for(let c in this.props.objects[o].loadedCosts){
              //console.log("adding ",this.props.objects[o].loadedCosts[c])
              costs.push(
                <Badge key={"badge_"+c+"_builtOn_"+o+"_"+theEmojiHere} outer={this.props.objects[o].loadedCosts[c].emoji} inner={this.props.objects[o].loadedCosts[c].amount}
                  size={8} color={Helpers.objectColor[1][0]} hightlight={Helpers.objectColor[1][1]}
                />
              )
            }
            let showButton = true
            let buildNear = false
            if(!this.state.nearByEmojis)
            {
              showButton = false
            }
            if(showButton && this.props.objects[o].builtNearEmoji){
              console.log("this.props.objects[o].builtNearEmoji",this.props.objects[o].builtNearEmoji)
              showButton = false
              for(let n in this.state.nearByEmojis){
                if(this.state.nearByEmojis[n].emoji==this.props.objects[o].builtNearEmoji){
                  buildNear = this.state.nearByEmojis[n]
                  showButton = true
                }
              }
            }
            if(showButton){
              buttons.push(
                <Scaler config={{startZoomAt:800,origin:"0% 50%",adjustedZoom:1}}>
                  <Button key={"builtOn_"+o+"_"+theEmojiHere} color={"blue"} size={"3"} onClick={async ()=>{

                    console.log("this.props.inventory",this.props.inventory,"this.props.objects[o].loadedCosts",this.props.objects[o].loadedCosts)

                      for(let c in this.props.objects[o].loadedCosts){
                        console.log("this.props.objects[o].loadedCosts",c,"is",this.props.objects[o].loadedCosts[c])
                        console.log("this.props.inventory[this.props.objects[o].loadedCosts[c].emoji]",this.props.inventory[this.props.objects[o].loadedCosts[c].emoji])
                        if(!this.props.inventory || !this.props.inventory[this.props.objects[o].loadedCosts[c].emoji] || parseInt(this.props.inventory[this.props.objects[o].loadedCosts[c].emoji])<parseInt(this.props.objects[o].loadedCosts[c].amount)){
                          this.props.setLoaderByTransaction({},"Not enough "+this.props.objects[o].loadedCosts[c].emoji,5000)
                          return;
                        }
                      }

                      this.props.setLoader(true,5,this.props.objects[o].loadedEmoji+" Building...")
                      if(buildNear){
                        //submitVote(uint256 proposalIndex, uint8 uintVote)
                        this.props.tx(
                          this.props.contracts["DAOG"].buildNear(buildNear.x,buildNear.y,this.state.x,this.state.y,this.props.web3.utils.toHex(this.props.objects[o].loadedEmoji)),
                          2000000,(result,err)=>{
                            console.log("CALLBACK",result)
                            this.props.setLoaderByTransaction(result,err,err?4500:1500)
                          }
                        )
                      }else{
                        //submitVote(uint256 proposalIndex, uint8 uintVote)
                        this.props.tx(
                          this.props.contracts["DAOG"].build(this.state.x,this.state.y,this.props.web3.utils.toHex(this.props.objects[o].loadedEmoji)),
                          2000000,(result,err)=>{
                            console.log("CALLBACK",result)
                            this.props.setLoaderByTransaction(result,err,err?4500:1500)
                          }
                        )
                      }

                      this.props.closeMenu()
                    }}>

                      {costs} Build {this.props.objects[o].loadedEmoji}

                  </Button>
                </Scaler>
              )
            }

          }
        }
      }
    }
    //check to see if this is the victory object
    //console.log("CHECKING VICTORY",this.props.victoryObjectEmoji,tile.emoji,tile.owner)
    if(this.props.victoryObjectEmoji  && tile.emoji==this.props.victoryObjectEmoji && tile.owner==this.props.account){
      buttons.push(
        <Button key="finish" color={"green"} size={"3"} onClick={async ()=>{
            this.props.setLoader(true,5,"🏁 Finishing...")
            //submitVote(uint256 proposalIndex, uint8 uintVote)
            this.props.tx(
              this.props.contracts["DAOG"].finish(this.state.x,this.state.y),
              2000000,(result,err)=>{
                console.log("CALLBACK",result)
                this.props.setLoaderByTransaction(result,err,err?4500:1500)
              }
            )
            this.props.closeMenu()
          }}>
          Claim Victory!
        </Button>
      )
    }

    //console.log("this.props.backgroundColor",this.props.backgroundColor)

    let contractAddress = ""
    if(tile.emoji && this.state.contract){
      contractAddress = this.state.contract._address
    }

    return (
      <div key="menu" style={{zIndex:7, position:'fixed',opacity:0.9,left:0,top:this.props.topPadding,width:"100%",height:"100%",backgroundColor:"#000000"}}>
        <div style={{cursor:"pointer",fontSize:"6vw",position:'absolute',right:"2%",top:"6%"}} onClick={this.props.closeMenu}>
          ❌
        </div>
        <div style={{padding:"5%"}}>
          <div style={{fontSize:"6vw",width:"80%",padding:10,backgroundColor:this.props.backgroundColor.replace("0x","#"),border:"1px solid #666666"}}>
            <span style={{float:'right',fontSize:"2vw",color:"#222222",opacity:0.5}}>{contractAddress}</span> {tile.emoji}
          </div>
          <div>
            <span style={{color:"#555555"}}> location: </span>
            {this.props.selection[0]},{this.props.selection[1]}
          </div>
          <div>
            <span style={{color:"#555555"}}> owner: </span>
            <Blockie address={tile.owner}/> {tile.owner}
          </div>
          <div>
            <span style={{color:"#555555"}}> neighbors: </span>
            {this.state.nearByEmojis?this.state.nearByEmojis.map(obj=>{
              return <span>{obj.emoji}</span>
            }):"loading..."}
          </div>
          <div style={{zIndex:7}}>
            {buttons}
          </div>
        </div>
      </div>
    )
  }
}
